import { useState } from "react"
import { FaRegEyeSlash } from "react-icons/fa6";
import { FaRegEye } from "react-icons/fa6";

import './input.scss'

export interface IInput {
   
    handleChanges(e: any): void
    handleBlur?(e: any): void
    value: any
    type?: string
    id: string
    name?: string
    label?: string
    readonly?: boolean
}

export const InputPassword = (props: IInput) => {
    const {value, handleChanges, id, name, label} = props
    const [showPass, setShowPass] = useState(false)
    return (
        <div className="input-wrapper">
            <span className="material-icons" onClick={() => setShowPass(!showPass)}>
                {showPass ? <FaRegEye /> : <FaRegEyeSlash />}
            </span>
            <input 
                onChange={handleChanges}
                id={id}
                type={showPass ? 'text' : 'password'}
                name={name} 
                value={value}                     
            />
            {label 
                ? <label  htmlFor={id} className="inp-label-int">{label}:</label> 
                : null
            }
        </div>
    )
}

export const Input = (props: IInput) => {
    const {value, handleChanges, id, name, label, type, readonly, handleBlur} = props
    return (
        <div className="input-wrapper">
           
            <input 
                onChange={handleChanges}
                onBlur={handleBlur}
                id={id}
                type={type}
                name={name} 
                value={value}
                readOnly={readonly}                     
            />
             {label 
                ? <label  htmlFor={id} className="inp-label-int">{label}:</label> 
                : null
            }
        </div>
    )
}
