import { useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Axios } from "../../common/axios"
import { I18n } from "../../assets/i18n"
import storage from "../../common/storage"
import { RoutePaths } from "../../constants/gameConstants"
import { useMessageShow } from "../../hooks/useShowMessage"
import { IRef } from "../../models/models"
import { Input, InputPassword } from "../../UI-components/Input/Input" 

import { loginSucceed } from "../../store/userSlice"
import { isDev } from "../../local-engine/gameplay-helper-fn"
import './auth.scss'
import { selectUserWithProps } from "../../store/rootState&Reducer"
import { getPuzzles, sendWsMessage } from "../../store/topStateSlice"
import { FadeIn } from "../../UI-components/FadeIn/FadeIn"

export const SigninPage: React.FC = () => {
    const [form, setForm] = useState({text: '', password: ''})
    const [attempts, setAttempts] = useState(0)
    // const [showPassword, setShowPassword] = useState(false)
    const {
        viewMode: mode, theme, language
    } = useSelector(selectUserWithProps(['viewMode', 'theme', 'exp', 'language']))
    const submitted = useRef(false)
    const formRef: IRef<any> = useRef()
    const navigate = useNavigate()
    const {showMessage, fadeMessage} = useMessageShow()
    const dispatch = useDispatch()
    const i18n = I18n[language.slice(0, 2)] || I18n.en

    const redirectSingup = () => {
        navigate(RoutePaths.signup)
    } 

    const forgotPassword = () => {
        navigate(RoutePaths.email)
    }

    const handleSignin = async () => {
        const reqData = {text: form.text, password: form.password}
        const reqBody = JSON.stringify(reqData)
        submitted.current = true
        setAttempts((attempts + 1))
        try {
            const response = await Axios.post('api/auth/login', reqBody)
            isDev() && console.log(response)
            const { data } = response
            dispatch(loginSucceed({...data}))
            storage.saveUser(data)
            navigate(`../`)
            dispatch(getPuzzles())
            submitted.current = false
        } catch(e: any) {
            showMessage({msg: e.response?.data?.message || e.message, ok: false})
            submitted.current = false
        }
    }

    const handleChanges = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault()
        const target = e.target as HTMLInputElement
        if (target.value) {
            target.parentElement!.classList.add('dirty')
        } else {
            target.parentElement!.classList.remove('dirty')
        }
        fadeMessage()
        setForm(form => ({...form, [target.name]: target.value}))
    }
    
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (submitted.current) return
        handleSignin()
    }

    return (
        <FadeIn>
        <div className={`auth-page theme-${theme} mode-${mode} "noselect"`} >
            <div className="form-wrapper " >
                <div className="form-title-wrapper">
                        <h1>{i18n.about_title || 'Welcome to Checkers Towers'}</h1>
                    <p><span>{i18n.logMess}</span></p>
                </div>
                
                <form onSubmit={handleSubmit} ref={formRef} autoComplete="on" >
                    <Input 
                        label={i18n.labelNickname}
                        id="text"
                        name="text"
                        type="text"
                        value={form.text}
                        handleChanges={handleChanges}
                    /> 
                    <InputPassword 
                        label={i18n.labelPassword}
                        id="password"
                        name="password"
                        value={form.password}
                        handleChanges={handleChanges}
                    />
                    <div className="extra-btn-wrapper">
                        <button type="button" onClick={redirectSingup} className="to-signup">
                            {i18n.modeButtonLog}
                        </button>
                        {attempts >= 3 
                            ? <button type="button" className="forgot" onClick={forgotPassword}>
                                    {i18n.forgotPassword}
                                </button>
                            :null
                        }
                    </div>
                    <input type="submit" value={i18n.submitButton} />
                </form>
            </div>
        </div>
        </FadeIn>
    )
}

export default SigninPage
