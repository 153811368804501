import { useDispatch, useSelector } from "react-redux"
import { selectUserWithProps } from "../../store/rootState&Reducer"
import { IPuzzle, IRootState } from "../../models/models"
import { PuzzleBoard } from "../../UI-components/Board/PuzzleBoard"
import storage from "../../common/storage"
import { choosePuzzle, selectWindowSize, setPuzzles } from "../../store/topStateSlice"
import { clearResolved } from "../../store/userSlice"
import { useState, useEffect } from "react"
import { I18n } from "../../assets/i18n"
// import { CustomSelect } from "../../UI-components/Input/MenuComponent"
import { GVs } from "../../constants/gameConstants"
import { setGameVariant } from "../../store/gameOptionsSlice"


const PuzzleAdmin = () => {
    const {puzzlesAdmin, resolvedPuzzles} = useSelector(selectUserWithProps(
        ['puzzlesAdmin', 'resolvedPuzzles'])
    ) as any
    const [all, setAll] = useState(false)
    const dispatch = useDispatch()
    const viewAll = (aa = !all) => {
        const pzs = storage.getPuzzles()
        const puzzles = aa
            ? pzs
            : pzs.filter((p: IPuzzle) => !resolvedPuzzles.includes(p._id))
        dispatch(setPuzzles(puzzles))
        setAll(aa)
    }
    const getBasic = () => {
        const puzz = require('../../assets/basicPuzzles.json')
            .slice().sort((a: IPuzzle, b: IPuzzle) => a.level - b.level)
        dispatch(setPuzzles(puzz))
    }

    if (!puzzlesAdmin) return null
    return  (
        <div className="puzz-admin">
            <button
                className="ctrl-btn" 
                type="button" 
                onClick={() => dispatch(clearResolved())}>
                Clear resolved
            </button>
            <button className="ctrl-btn" type="button" onClick={() => viewAll(!all)}>
                {!all ? 'view all' : 'view unresolved'}
            </button> 
            <button className="ctrl-btn" type="button" onClick={getBasic}>
                {'Get basic puzzles'}
            </button>
        </div>
    )
}

export const PuzzlesMenu = () => {
    const {width, height} = useSelector(selectWindowSize)
    const pzs = useSelector((state: IRootState) => state.topState.puzzles)
    const admin = useSelector((state: IRootState) => (state.user as any).puzzlesAdmin)
    const GV = useSelector((state: IRootState) => state.gameSettings.gameVariant)
    const {language, token} = useSelector(selectUserWithProps(['token', 'language']))
    const i18n = I18n[language.slice(0, 2)] || I18n.en
    const dispatch = useDispatch()
    const onClick = (id: string) => dispatch(choosePuzzle(id))
    const setFilter = (f: string) => {
        dispatch(setGameVariant({GV: f}))
    }

    const sortedPuzzles = [...pzs].sort((a: IPuzzle, b: IPuzzle) => a.level - b.level)

    // const filterMenu = ['towers', 'russian', 'international'].map(i => ({name: i18n[i], value: i}))
    if (!sortedPuzzles.length && !token) {
        return (
            <div className="puzzles-menu">
                <h2>{i18n.signToGetPuzzles}</h2>
                <div className="puzzles-menu-filter">
                    {GVs.map(gv => (
                        <button 
                            type="button" 
                            onClick={() => setFilter(gv)} 
                            className={`puzzles-menu-btn${GV === gv ? ' actual' : ''}`}
                        >
                            {i18n[gv]}
                        </button>
                    ))}
                </div>
            </div>
        )
    }
    
    return (
        <>
            <PuzzleAdmin />
            <div className="puzzles-menu">
                <div className="puzzles-menu-filter">
                    {GVs.map((gv, i) => (
                        <button 
                            key={i}
                            type="button" 
                            onClick={() => setFilter(gv)} 
                            className={`puzzles-menu-btn${GV === gv ? ' actual' : ''}`}
                        >
                            {i18n[gv]}
                        </button>
                    ))}
                </div>
                <div className="puzzles-menu-wrapper">
                {
                    sortedPuzzles.map((p: IPuzzle) => {
                        return (
                            <button
                                className="puzzles-menu_item"
                                key={p?._id} 
                                type="button" 
                                onClick={() => onClick(p._id)}>
                                <span className="title">
                                    {p?.description || p?.name}
                                    &nbsp;
                                    { admin ? p?.level : ''}
                                </span>
                                <PuzzleBoard puzzle={p} cellBase={25} />
                            </button>
                        )
                    })
                }
                </div>
            </div>
        </>
    )
}
