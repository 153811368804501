import { 
    EngineLevels, EvValLim, MPS, FNotationMap, TPS, RNotationMap, ValueGap,
    CrossCutW1_8,
    CrossCutW2_8,
    CrossCutW3_8,
    CrossCutW1_10,
    CrossCutW2_10,
    CrossCutW3_10,
    CrossCutW4_10,
    CollatW1,
    CollatW2,
    LargeDiagonal,
    CollatW4,
    CrossCutB1_8,
    CrossCutB2_8,
    CrossCutB3_8,
    CollatB1,
    CollatB2,
    CrossCutB1_10,
    CollatB4,
    CollatB3,
    CollatW3,
} from '../constants/gameConstants'
import {
    CellsMap,
    IPiecePosition,
    MMRResult,
    PieceColor as PC,
    IBoardPieces,
    Branch,
    DeepValue,
    IMoves,
    IGoTo,
    Move,
    IGame,
    IShortPieces,
    IBoardBase,
    IBoardTower,
    IPieces,
    GameResult,
    IPlayer,
    GameVariant,
    IExpirience,
    IMovesPair,
    IPuzzle,
    AppType,
    BoardNotation,
} from '../models/models'
import { calcPiecePosition, getDirection } from './board-helper-fn'

import MoveResolver from './move-resolver'
import { IBestMoveLine } from './moves-tree'

export const includeS = (ar: string[], el: string) => {
    for (const e of ar) {
        if (e === el) return true
    }
    return false
}

export const delayer = (adds = 2) => {
    return Math.round(Math.random() * 3000 * adds) - 1000
}

export const calcCellSize = (
    type: AppType, wS: {width: number, height: number}, bS: number, cS: number
) => {
    const {width, height} = wS
    if (!width) return {cellSize: cS}
    const hLim = (type === AppType.game || AppType.analysis === type) ? .78 : .55
    const wLim = type === AppType.analysis ? .8 : 1
    const avWidth = width > 900
        ? Math.min(width < 1100 ? width - 250 - 10*bS : width - 500 - 10*bS, 600, height * hLim)
        : Math.min(width*wLim*.86, 600, height * hLim)
    const nCS = Math.max(Math.floor(avWidth/bS/5)*5, 30)
    // console.log(cS, nCS, type, wS, avWidth, Math.floor(avWidth/bS/10)*10, hLim, height * hLim)
    return {cellSize: nCS}
    // return {cellSize: cS}
}

export const splitMove = (move: string) => {
    if (!move) return []
    if (move.includes(TPS)) { 
        return move.split(TPS)
    }
    return move.split(MPS)
}

export const getRandomDebuteMove = (sortedMoves : string[]) => {
    const moves = sortedMoves
    moves.push(moves[0])
    moves.push(moves[0])
    moves.push(moves[1])
    return moves[Math.floor(Math.random() * moves.length)]
}

export const getXY = (event: any) => {
    return event.type.includes('touch')
            ? event.changedTouches['0'] 
            : event
}

export const posAreEquil = (pos1: IBoardPieces, pos2: IBoardPieces) => 
    positionSummary(pos1) === positionSummary(pos2)

export function checkDrawTowers(game: IGame) {
    const {idleInRow, moves, position, turn} = game
    if (idleInRow === 15) return true
    if (idleInRow > 2) {
        let repetitions = 1
        for (let i = 1; i < idleInRow; i++) {
            const prevPos = moves[moves.length - 1 - i][turn]!.position
            // console.warn()
            if (posAreEquil(position, prevPos as IBoardPieces)) {
                    repetitions += 1
                    if (repetitions === 3) return true
            }
        }
        // isDev() && console.warn(moves.length, idleInRow, repetitions)
        return false
    }
}

export const getSDiags = (color: PC, size: number, small = false) => {
    switch (true) {
        case (color === PC.white && size == 8 && small): {
            return [CrossCutW1_8, CrossCutW2_8, CrossCutW3_8]
        }
        case (color === PC.white && size == 8 && !small): {
            return [LargeDiagonal.slice(0, -2), CollatW1.slice(0, -2), CollatW2.slice(0, -2), CollatW3.slice(0, -2)]
        }
        case (color === PC.white && size == 10 && small): {
            return [CrossCutW1_10, CrossCutW2_10, CrossCutW3_10, CrossCutW4_10]
        }
        case (color === PC.white && size == 10 && !small): {
            return [LargeDiagonal, CollatW1, CollatW2, CollatW3, CollatW4]
        }
        case (color === PC.black && size == 8 && small): {
            return [CrossCutB1_8, CrossCutB2_8, CrossCutB3_8]
        }
        case (color === PC.black && size == 8 && !small): {
            return [LargeDiagonal.slice(0, -2), CollatB1.slice(0, -2), CollatB2.slice(0, -2), CollatB3.slice(0, -2)]
        }
        case (color === PC.black && size == 10 && small): {
            return [CrossCutB1_10, CrossCutB1_8, CrossCutB2_8, CrossCutB3_8]
        }
        case (color === PC.black && size == 10 && !small): {
            return [LargeDiagonal, CollatB1, CollatB2, CollatB3, CollatB4]
        }
        default: return [] as string[][]
    }
}

export const getBlockedSector = (key: string, diags: string[][]) => {
    for (let i = 0; i < diags.length - 1; i++) {
        if (includeS(diags[i], key)) {
            return diags.slice(i+1).reduce((acc, j) => {acc = acc.concat(j); return acc}, [])
        }
    }
    return []
}

export const getIntersectionValue = (arr1: string[], arr2: string[]) => {
    if (!arr1.length || !arr2.length) return 0
    const interSec = arr1.filter(i => includeS(arr2, i))
    return 2 * ((interSec.length) / (arr2.length))
}

export function checkIdle(move: string, position: IBoardPieces) {
    return !move.includes(TPS) && position[getTo(move)].king
}

export const diagsLength8x8 = (key: string) => {
    const [lett, num] = [key[0], +key.slice(1)]
    switch (true) {
        case key === 'd4' || key === 'e5':
            return 15
        case lett === 'a' || lett === 'h' || num === 1 || num === 8:
            return 9
        case lett === 'b' || lett === 'g' || num === 2 || num === 7:
            return 11
        default:
            return 13
    }
}

export const getSectors8x8 = (key: string): string[] => {
    switch (true) {
        case key === 'e5' || key === 'f6':
            return []
        default: 
            return []
    }
}

export const diagsLength10x10 = (key: string) => {
    const [lett, num] = [key[0], +key.slice(1)]
    switch (true) {
        case key === 'e5' || key === 'f6':
            return 19
        case lett === 'a' || lett === 'k' || num === 1 || num === 10:
            return 11
        case lett === 'b' || lett === 'i' || num === 2 || num === 9:
            return 13
        case lett === 'c' || lett === 'h' || num === 3 || num === 8:
            return 15
        default: 
            return 17
    }
}

export const getElementPosition = (el: HTMLElement): IPiecePosition => {
    const {x, y, width, height} = el.getBoundingClientRect()
    return {x: x + width / 2, y: y + height / 2}
}

export const oppositeColor = (color: PC): PC =>
    color === PC.white ? PC.black : PC.white

export const oppositeDirection = (dir: string): string => {
    switch (dir) {
        case 'leftDown':
            return 'rightUp'
        case 'rightUp':
            return 'leftDown'
        case 'leftUp':
            return 'rightDown'
        default:
            return 'leftUp'
    }
}

export const copyObj = (board: any): any => {
    if (typeof board !== "object") return board
    if (Array.isArray(board)) {
        return board.map((i) => copyObj(i))
    } else if (board instanceof Date) {
        return board
    } else {
        const copy = {} as {[key: string]: any}
        for (const key in board) {
            copy[key] = copyObj(board[key])
        }
        return copy
    }
}

export const normaFn = (val: number) => {
    const absVal = Math.abs(val)
    if (absVal === Infinity) return val < 0 ? -1 : 1
    return val / (EvValLim + absVal)
}

export const getTo = (move: string) => {
    const splitedMove = splitMove(move)
    return splitedMove[splitedMove.length - 1]
}

export const getFromTo = (move: string) => {
    const splitedMove = splitMove(move)
    return [splitedMove[0], splitedMove[splitedMove.length - 1]]
}

export const getPrevPosition = (moves: IMoves, turn: PC): IBoardPieces => {
    return turn === PC.white
        ? moves[moves.length - 1].white!.position
        : moves[moves.length - 2].black!.position    
}

export const delay = (time: number) => new Promise(async (resolve) => {
    setTimeout(resolve, time);
});

export const waiter = async (time: number, fn: Function) => {
    await delay(time);
    return fn();
}

export const ratingCalc = (r1: number, r2: number) => {
    switch (true) {
        case Math.abs(r1 - r2) <= 20: 
            return [10, 0, -10]
        case Math.abs(r1 - r2) < 40: 
            return r1 > r2 ? [9, 1, -11] : [11, -1, -9]
        case Math.abs(r1 - r2) < 100: 
            return r1 < r2 ? [12, 2, -8] : [8, -2 -12]
        case Math.abs(r1 - r2) < 200: 
            return r1 < r2 ? [14, 2, -6] : [6, -2 -14]
        case Math.abs(r1 - r2) < 300: 
            return r1 < r2 ? [16, 3, -3] : [3, -3, -16]
        case Math.abs(r1 - r2) < 350: 
            return r1 < r2 ? [18, 5, -1] : [1, -5, -18]
        default: 
            return r1 < r2 ? [20, 6, -1] : [0, -6, -18]
    }
}

export const createDeepValue = (depth: number, turn: PC): DeepValue => {
    const value = turn === PC.white ? -Infinity : Infinity
    return { depth, value, move: ''}
}

    
export const updateDV = (deepValue: DeepValue, order: PC, childBranch: Branch) => {
        // console.log('update', order, childBranch.deepValue, childBranch.move, deepValue)
    if (order === PC.white && childBranch.deepValue.value >= deepValue.value) {
        deepValue.value = childBranch.deepValue.value
        deepValue.move = childBranch.move
    } 
    if (order === PC.black && childBranch.deepValue.value <= deepValue.value) {
        deepValue.value = childBranch.deepValue.value
        deepValue.move = childBranch.move
    }
}

export const addSolutionToMoves = (moves: IMoves, sol: Move[]) => {
    const nextNum = +(moves.length && (moves.length - +!moves[moves.length - 1].black))
    const baseMoves = moves.slice(0, nextNum)
    let tail = moves[nextNum] ? {...moves[nextNum]} : {}
    let turn = tail.white ? PC.black : PC.white
    for (const move of sol) {
        if (turn === PC.black) {
            tail.black = move
            baseMoves.push(tail)
            tail = {}
        } else {
            tail.white = move
        }
        turn = oppositeColor(turn)
    }
    if (tail.white) {
        baseMoves.push(tail)
    }
    return baseMoves
}

export const getOnbRatings = (exp: IExpirience) => {
    const {
        tow: {puzzles: tPuz, dRate: tRate}, rus: {puzzles: rPuz, dRate: rRate}, int: {dRate: iRate}
    } = exp
    switch (true) {
        case tPuz >= 10: {
            return {
                tow: {rating: Math.max(900, tRate), date: new Date()},
                rus: {rating: Math.max(900, rRate), date: new Date()},
                int: {rating: Math.max(900, iRate), date: new Date()}
            }
        }
        case tPuz >= 9: {
            return {
                tow: {rating: 800, date: new Date()},
                rus: {rating: 800, date: new Date()},
                int: {rating: 800, date: new Date()}
            }
        }
        case tPuz >= 8: {
            return {
                tow: {rating: 700, date: new Date()},
                rus: {rating: 700, date: new Date()},
                int: {rating: 700, date: new Date()}
            }
        }
        case tPuz >= 7: {
            return {
                tow: {rating: 600, date: new Date()},
                rus: {rating: 600, date: new Date()},
                int: {rating: 600, date: new Date()}
            }
        }
        case tPuz >= 6: {
            return {
                tow: {rating: 550, date: new Date()},
                rus: {rating: 550, date: new Date()},
                int: {rating: 550, date: new Date()}
            }
        }
        case rPuz >= 5: {
            return {
                tow: {rating: 400, date: new Date()},
                rus: {rating: 400, date: new Date()},
                int: {rating: 400, date: new Date()}
            }
        }
        case tPuz >= 4: {
            return {
                tow: {rating: 300, date: new Date()},
                rus: {rating: 300, date: new Date()},
                int: {rating: 300, date: new Date()}
            }
        }
        case tPuz >= 3: {
            return {
                tow: {rating: 50, date: new Date()},
                rus: {rating: 200, date: new Date()},
                int: {rating: 200, date: new Date()}
            }
        }
        default: {
            return {
                tow: {rating: 50, date: new Date()},
                rus: {rating: 50, date: new Date()},
                int: {rating: 50, date: new Date()}
            }
        }
    }
}

export const dirToTarget = (moves: string[][], ind: string) => {
    for (const move of moves) {
        const index = move.indexOf(ind)
        if (index > 0) {
            return [getDirection(move[index - 1], move[index]), index]
        }
    }
    return []
}

export const getNewExp = (gv: GameVariant, exp: IExpirience, rl: number, res: GameResult) => {
    const GV = gv.slice(0, 3)
    const {num = 0, highest = 50, winrate = 0} = exp[GV].offGames || {}
    const botRate = EngineLevels[rl]
    const [win, sep, div] = res.split('').map(i => parseInt(i))
    const _winrate = !num 
        ? win / (div || 1)
        : Math.min((num * winrate + win) / (num + (div || 1)), 1)
    // console.log('new winrate', winrate, res, num, win, div, _winrate)
    return {
        ...exp,
        [GV]: {
            ...exp[GV], 
            offGames: { 
                num: num + 1,
                highest: !highest || (highest < botRate) ? botRate : highest,
                winrate: Math.floor(_winrate * 1000)/1000
            }
        }
    }
}

export const copyObj2 = (obj: any): any => {
    if (typeof obj !== 'object') return obj
    return JSON.parse(JSON.stringify(obj))
}

export const getTouchedPieceMoveCells = (
    cellsMap: CellsMap, 
    state: IGame,
    key: string
): CellsMap => {
    const {nextMoves, moveStep} = state
    // console.log(cellsMap, state.nextMoves, state.moveStep, key)
    return nextMoves.reduce((acc, move) => {
        if (move.move[0] === key) {
            for (const cKey of move.move.slice(moveStep + 1)) {
                acc[cKey] = cellsMap[cKey]
            }
        }
        return acc
    }, {} as CellsMap)
}

export 
const filterAndSort = (puzzles: IPuzzle[], resolved: string[], GV = 'towers') => {
    return puzzles
            .filter(m => m.gType === GV.slice(0, 3) && !resolved.includes(m._id))
            .sort((a: IPuzzle, b: IPuzzle) => a.level - b.level)
}

export const getDemoCellSize = (winSize: {width: number, height: number}, bS: number) => {
    switch (true) {
        case (winSize.width > 960 && winSize.height > 500): {
            return 30
        }
        case (winSize.height > 500 && winSize.width > 560 && winSize.width <= 960): {
            return 25
        }
        case (winSize.height > 500 && winSize.width <= 560): {
            return 20
        }
        default:
            return 25
    }
}

export const getExampleCellSize = (winSize: {width: number, height: number}, bS: number) => {
    switch (true) {
        case (winSize.width < 560): {
            return 35
        }
        case (winSize.width >= 560 && winSize.width < 960): {
            return 30
        } 
        case (winSize.width >= 960 && winSize.width < 1200): {
            return 40
        } 
        case (winSize.width >= 1200): {
            return 45
        }     
        default:
            return 30
    }
}

export const sumMoves = (moves: MMRResult[], m: MMRResult[], f: MMRResult[]) => {
    if (!moves.length) return [m, f]
    if (moves[0].takenPieces) {
        return [m.concat(moves), f]
    } else {
        return [m, f.concat(moves)]
    }
}

export const notaConvert = (move: string, GV: GameVariant, nota: BoardNotation) => {
    if (
        !move 
        || nota === BoardNotation.ch
        || (GV !== 'international' && nota === BoardNotation.au)) { return move }
    const mapper = (GV === 'international') 
        ? FNotationMap 
        : RNotationMap
    const splitedM= splitMove(move).map(m => mapper[m])
    const sep = move.includes(TPS) ? TPS : MPS
    
    return splitedM.join(sep)
}

export function filterMandatoryMoves(moves: MMRResult[], kings: string[]) {
    const res = kings.length
    ? moves.filter(m => kings.includes(m.move[0]))
    : moves
    const sortedRes = res.length 
        ? res.sort((a,b) => b.move.length - a.move.length)
        : moves.sort((a,b) => b.move.length - a.move.length)
    return sortedRes.filter(m => m.move.length === sortedRes[0].move.length)  
}

export function checkMoveTargetCell(
    cursPos: IPiecePosition,
    cM: CellsMap,
    cellSize: number,
    rect: DOMRect
) {
    const [x, y] = [cursPos.x - rect.x, cursPos.y - rect.y]
    return x < 0 || y < 0
        ? null
        : Object.keys(cM).filter((key: string) => {
              const cellPos = cM[key]
              const [centerX, centerY] = [
                  cellPos.x + cellSize * .5,
                  cellPos.y + cellSize * .5,
              ]
              const distance = Math.sqrt(
                  Math.pow(centerX - x, 2) + Math.pow(centerY - y, 2)
              )
              return distance < cellSize * .8
          })[0]
}

export const isNextPosition = (pos: IPieces, _move: Move, mr: any) => {
    const {move, position} = _move
    try {
        const nextPos = mr.makeMoves([move], pos)
        return positionSummary(position) === positionSummary(nextPos)
    } catch(e) {
        return false
    }
}

export const getRandomFromBests = (moveLines: IBestMoveLine[], turn: PC, gap = ValueGap) => {
    const engineWhite = turn === PC.white
    const bestVal = moveLines.reduce((res, l) => {
        if ((!engineWhite && l.value < res)
            || (engineWhite && l.value > res)) {
            res = l.value
        }
        return res
    }, engineWhite ? -Infinity : Infinity)
    
    const movesWithSameVal = moveLines.filter(l => engineWhite 
        ? l.value >= bestVal - gap
        : l.value <= bestVal + gap
    )
    return movesWithSameVal[Math.floor(Math.random() * movesWithSameVal.length)] 
}

export const positionSummary = (pos: IBoardPieces) => {
    let res = '' 
    const keys = Object.keys(pos).sort()
    for (const k of keys) {
        res += `, ${k} c:${pos[k].color}`
        if (pos[k].white) {
            res +=` w:${pos[k].white}`
        }
        if (pos[k].black) {
            res +=` b:${pos[k].black}`
        }
        if (pos[k].king) {
            res += `, k`
        }
    }
    return res
}

export const shortPosition = (pos: IBoardPieces): IShortPieces => {
    const res = {} as IShortPieces
    for (const k in pos) {
        res[k] = {
            c: pos[k].color, 
        }
        if (pos[k].white) {
            res[k].w = pos[k].white
        }
        if (pos[k].black) {
            res[k].b = pos[k].black
        }
        if (pos[k].king) {
            res[k].k = 1
        }
    }
    return res
}


export const updateRating = (player: IPlayer, res: GameResult, color: PC) => {
    let {rating, ratingChanges} = player
    const ind = getChangeInd(res, color)
    return rating + (ratingChanges as number[])[ind]
}

export const getChangeInd = (res: GameResult, color: PC) => {
    return res === GameResult[color] ? 0 : (res === GameResult.draw ? 1 : 2)
}

export const positionWithoutDom = (pos: IBoardPieces): IPieces => {
    const towers = {} as IPieces
    for (const key in pos) {
        const {DOM, ...tower} = pos[key]
        towers[key] = tower
    }
    return towers
}

export const positionWithDom = (pos: IPieces, move: string[], board: IBoardBase) => {
    const {cellsMap, cellSize} = board
    const position = {} as IBoardPieces
    for (const key in pos) {
        position[key] = {
            ...pos[key],
            DOM: move 
                ? calcPiecePosition(move[0], cellsMap, cellSize)
                : calcPiecePosition(key, cellsMap, cellSize)
        }
    }
}

export const fullPosition = (pos: IShortPieces | any, board?: IBoardBase): IBoardPieces => {
    const {cellSize = 0, cellsMap = {}} = board || {}
    const position = {} as IBoardPieces
    for (const key in pos) {
        let Piece = {
            color: pos[key].c,
        } as IBoardTower
        if (pos[key].w || pos[key].b) {
            Piece.white = pos[key].w || 0
            Piece.black = pos[key].b || 0
        }
        if (pos[key].k || pos[key].t === 'king') {
            Piece.king = true
        }
        if (cellSize) {
            Piece = {...Piece, DOM: calcPiecePosition(key, cellsMap, cellSize)}
        }
        position[key] = Piece
    }
    return position
}

export const getEngLevel = (rating: number) => {
    return Math.round(rating / 300)
}

export const getKingsNumber = (position: IPieces) => {
    let kings = 0
    let pieces = 0
    for (const key in position) {
        pieces += 1
        if (position[key].king) {
            kings += 1
        }
    }
    return [kings, pieces]
}

export const checkPosition = (pos: IBoardPieces): boolean => {
    let w = 0, b = 0;
    for (const p in pos) {
        w += pos[p].white!
        b += pos[p].black!
    }
    return w === b
}

export const addKings =(pos: IBoardPieces) => {
    const position = {} as IBoardPieces
    for (const k in pos) {
        const {type, ...tower} = pos[k] as any
        position[k] = tower
        if (type === 'king') {
            position[k].king = true
        }
    }
    return position
}

export const movesFromSolution = (sol: string[], pos: IBoardPieces, MR: MoveResolver): Move[] => {
    const res = [] as Move[]
    let prevPos = pos
    for (const move of sol) {
        const position = MR.makeMoves([move], prevPos)
        res.push({move, position})
        prevPos = position
    }
    return res
}

export const getMovesToPlay = (moves: IMoves, lm: IGoTo): Move[] => {
    const rest = lm.turn 
        ? moves.slice(lm.num + (lm.turn === PC.black ? 1 : 0 ))
        : moves.slice()
    const lastTurn = lm.turn || PC.black  
    return rest.reduce((acc, m, i) => {
        const {white, black} = m as IMovesPair
        if (i || lastTurn === PC.black) {
            acc.push({move: white.move, position: white.position as unknown as IBoardPieces})
        }
        if (black) {
            acc.push({move: black.move, position: black.position as unknown as IBoardPieces})  
        }
        return acc
    }, [] as Move[])
}

export const getPrevMove = (move: IGoTo, stT = PC.white): IGoTo => {
    const {num, turn} = move
    if (!turn || (!num && (turn === PC.white || stT === PC.black))) return {} as IGoTo
    return {
        num: turn === PC.white 
            ? (num > 0 ? num - 1 : 0)
            : num,
        turn: oppositeColor(turn)
    }
}

export const getNextMove = (move: IGoTo): IGoTo => {
    const {num, turn} = move
    if (!turn) {
        return {num: 0, turn: PC.white}
    }
    return {
        num: turn === PC.black 
            ? num + 1
            : num,
        turn: oppositeColor(turn)
    }
}

export const getLastMoveProps = (moves: IMoves, last: IGoTo): Move => {
    if (!last.num || !last.turn) {
        return {} as Move
    }
    return  {...moves[last.num][last.turn]} as Move
}

export const getLastMove = (moves: IMoves) => {
    if (!moves.length) {
        return {} as IGoTo
    }
    return {
        num: moves.length - 1, 
        turn: moves[moves.length - 1].black ? PC.black : PC.white
    }
}

export const removeMandatory = (pos: IBoardPieces): IBoardPieces => {
    const position = {} as IBoardPieces
    for (const k in pos) {
        const {mandatory, ...piece} = pos[k]
        position[k] = piece
    }
    return position
}

export const onbPuzzleStartLevel = (exp: IExpirience) => {
    const {
        tow: {dRate: tRate}, 
        int: {dRate: iRate},
        rus: {dRate: rRate}
    } = exp
   
    const maxRate = Math.max(tRate, iRate, rRate)
    // const maxRules = Math.max(tRules, iRules, rRules)
    switch (true) {
        case (maxRate <= 50): {
            return 0
        }
        case (maxRate <= 300): {
            return 3
        }
        case (maxRate <= 600): {
            return 4
        }
        case (maxRate <= 2400): {
            return 6
        }
        default: {
            return 0
        }
    }
}

export const isDev = () => {
    return (
        process.env.NODE_ENV !== 'production'
    )
}

export const parsePuzzles = (puzzles: IPuzzle[]) => {
    
    return puzzles.map((p: IPuzzle) => {
        const puzzle = {
            ...p, 
            position: typeof p.position === 'string' 
                ? addKings(JSON.parse(p.position)) 
                : p.position,
            solution: typeof p.solution === 'string' 
                ? p.solution.split('_') 
                : p.solution
        } as IPuzzle
        
        if (p.eSolution?.length) {
            puzzle.eSolution = typeof p.eSolution === 'string' 
                ? p.eSolution.split('_') 
                : p.eSolution
        }
        return puzzle
    })
}

// export const solutionToMoves = (sol: string[], position: IBoardPieces, MR: MoveResolver): IMoves => {
//     return (sol as string[]).reduce((acc, move, i) => {
//         const step = Math.floor(i / 2)
//         const prevPosition = !i 
//             ? position 
//             : (i % 2 
//                 ? acc[step].white?.position as IBoardPieces
//                 : acc[step > 0 ? step - 1 : 0].black?.position as IBoardPieces
//             )
//         const nextPosition = MR.makeMoves([move], prevPosition)
//         if (!(i % 2)) {
//             acc.push({white: {move: move, position: nextPosition}})
//         } else {
//             acc[step].black = {move, position: nextPosition}
//         }
//         return acc
//     }, [] as Partial<IMovesPair>[])
// }

export const getMoves = (moves1: IMovesPair[], moves2: IMovesPair[]) => {
    const lM = getLastMove(moves1)
    if (lM.num < moves2.length - 1 
        && posAreEquil(moves1[lM.num][lM.turn].position, moves2[lM.num][lM.turn].position)) {
            return moves2
    }
    return moves1
}

export const filterKingMoves = (moves: MMRResult[]): MMRResult[] => {
    let result = [] as MMRResult[]
    const sortedMoves = moves.sort((a, b) => a.takenPieces!.length - b.takenPieces!.length)
    for (let i = 0; i < sortedMoves.length; i++ ) {
        const move = sortedMoves[i]
        const indexOfRightMove = sortedMoves.slice(i).findIndex(m => {
            return m.takenPieces!.join('').startsWith(move.takenPieces!.join(''))
                && m.takenPieces!.length > move.takenPieces!.length
        })
        if (indexOfRightMove < 0) {
            result.push(move)
        }
    } 
    return result
}

export const equalArrays = (arr1: string[], arr2: string[]): boolean => {
    if (arr1.length !== arr2.length) return false
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false
    }
    return true
}

export const includesArray = (arr1: string[][], arr2: string[]): boolean => {
    for (let arr of arr1) {
        if (equalArrays(arr, arr2)) return true
    }
    return false
}

const fn = {
    // checkIfNumberOfKingsChanged,
    copyObj,
    oppositeColor,
    oppositeDirection,
    // getPossibleMoves: getPiecePossibleMoves,
    includesArray,
    equalArrays,
}

export default fn
