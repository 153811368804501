import { all } from 'redux-saga/effects';
import watcherGameState from './watcherGameState';
import watcherUser from './watcherUser';
import watcherPreGame from './watcherGamePresets';
// import watcherAnalsis from './watcherAnalysis'
import watcherGame from './watcherGame';
import watcherPuzzles from './watcherPuzzles';
// import watcherBoard from './watcherBoard';

export default function* sagaRoot() {
    yield all([
        watcherPuzzles(),
        watcherPreGame(), 
        watcherUser(),
        watcherGameState(),
        // watcherAnalsis(),
        watcherGame(),
    ]);
}
