import { useDispatch, useSelector } from "react-redux"
import { I18n } from "../../assets/i18n"
import { Steps } from './OnbordingStep'
import { useEffect, useState } from "react"
import storage from "../../common/storage"
import { selectUserWithoutProps } from "../../store/rootState&Reducer"
// import { Axios, setAuthorizationHeader } from "../../common/axios"
import { setBackMessage, setOnboardingStep, setPuzzles, updateAppState } from "../../store/topStateSlice"
// import { getOnbRatings } from "../../local-engine/gameplay-helper-fn"
// import { saveUser } from "../../store/userSlice"

import './onb.scss'
import { IRootState } from "../../models/models"
import { SkipModal, StepIndicator } from "./StepIndicator"
import { FadeIn } from "../FadeIn/FadeIn"
import { useNavigate } from "react-router-dom"
import { saveUser } from "../../common/axios"
import { updateExp } from "../../store/userSlice"

const stepsLength = 3

export const Onboarding = () => {
    const userData = useSelector(selectUserWithoutProps(['games', 'userId', '_v']))
    const step = useSelector((state: IRootState) => state.topState.onboardingStep)
    const GV = useSelector((state: IRootState) => state.gameSettings.gameVariant)
    const {
        language, name, viewMode, theme, token
    } = userData
    const i18n = I18n[language.slice(0, 2)] || I18n.en
    const navi = useNavigate()
    const dispatch = useDispatch()
    const [skipModal, setSkipModal] = useState(false)

    const nextStep = () => {
        dispatch(updateAppState({
            puzzleResolved: null, solution: [], selectedPuzzle: '', onboardingStep: step + 1
        }))
        if (!step) return
        
        if (token) {
            dispatch(setPuzzles(
                storage.getPuzzles().filter(p => p.gType !== GV.slice(0, 3)).slice(0, 6)
            ))
        }
        const user = {...userData, exp: {...userData.exp, onboardingPassed: true}}
        saveUser(user, (m) => dispatch(setBackMessage(m)))
        setTimeout(() => dispatch(updateExp({onboardingPassed: true})))
    }

    const handleConfirmSkip = () => {
        setSkipModal(false)
        nextStep()
        setTimeout(() => navi('/settings'))
    }
    
    useEffect(() => {
        const step = Math.max(Object.keys(storage.getUser().exp.tow).length - 1, 0)
        dispatch(setOnboardingStep(step))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleStep = (step: number) => {
        if (step < stepsLength - 1) {
            return dispatch(setOnboardingStep(step))
        }        
    }
    // const Ps = [i18n.stepOneTitle, i18n.stepTwoTitle, i18n.stepPuzzlesTitle, i18n.stepLastTitle]
    
    return (
        <FadeIn>
            <main className={`onboarding-wrapper mode-${viewMode} theme-${theme} noselect`}>
                
                <section className={`onboarding step-${step}`}>       
                    <StepIndicator />
                    {!step 
                        ? <header className={'onboarding_header'}>
                            <h3>{i18n.hi} {name}!</h3> 
                            <p>{i18n.stepOneTitle}</p>
                        </header>
                        : null
                    }
                    <FadeIn>
                        <Steps step={step} confirm={handleStep as (s: number) => {}} />
                    </FadeIn>
                </section>
                {skipModal 
                    ? <SkipModal close={() => setSkipModal(false)} skip={handleConfirmSkip}/> 
                    : null
                }
            </main>
        </FadeIn> 
    )
}

export default Onboarding
