/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { ITopState, PieceColor as PC } from './models/models'
import {
    selectUserWithProps,
    selectWithProps, 
} from './store/rootState&Reducer'
import { delayer, fullPosition, getEngLevel, isDev } from './local-engine/gameplay-helper-fn'

import { wsService } from './common/ws-service'
import { TopState } from './constants/gameConstants'
import { startEngine } from './store/topStateSlice'
import { createStartPosition } from './local-engine/board-helper-fn'


let enWorker = null as any

export const OnlineBot = () =>  {
    const dispatch = useDispatch()
    const [ws, setWs] = useState(wsService.ws)
    const engineColor = useRef(PC.white)
    const gameKeyR= useRef('')
    const move = useRef({} as any)
    const {localEngine} = useSelector(selectWithProps(['localEngine'], TopState)) as ITopState
    const {userId} = useSelector(selectUserWithProps(['userId']))
    const proccessMessage = (props: {type: string, data: any}) => {
        isDev() && console.log(props.type, props.data, engineColor)
        switch (props.type) {
            case 'created': {
                ws.send(JSON.stringify({
                    message: 'game ready to play', 
                    payload: {
                        [engineColor.current === PC.white ? 'wReady' : 'bReady']: 1,
                        gameKey: gameKeyR.current,
                        
                    },
                    from: wsService.onlineBot
                }))
                break
            }
            case 'game surrender': {
                return ws.send(JSON.stringify({
                    message: props.type, 
                    from: wsService.onlineBot, 
                    payload: {gameKey: gameKeyR.current}
                }))
            }
            case 'move': {
                const payload = JSON.stringify({
                    message: 'game move', 
                    payload: {
                        move: props.data.move,
                        gameKey: gameKeyR.current,  
                    },
                    from: wsService.onlineBot
                })
                if (!props.data?.move) {
                    return
                }
                return setTimeout(() => ws.send(payload), delayer())
            }
            default: 
                break
        }
    }

    const wsMessageHandler = (data: {message: string, payload: any, to: string}) => {
       
        if (userId === data.to || wsService.onlineBot !== data.to) return
        // console.log('new mess to bot', data, userId, wsService.onlineBot)
        switch(data.message) {
            case 'new game': {
                enWorker = new Worker(new URL('./local-engine/full-engine.js', import.meta.url))
                enWorker.onmessage = (e: {data: {type: string, data: Object}}) => {
                    proccessMessage(e.data)
                }
                const {
                    black, white, GV, bSize, gameKey, turn = PC.white
                } = (data.payload || {}) as any
                if (!black || !white || !GV || !gameKey || !bSize) return
                engineColor.current = white.userId === userId ? PC.black : PC.white
                gameKeyR.current = gameKey
                const SetupProps = {
                    engineColor: engineColor.current,
                    engineLevel: getEngLevel(white.rating),
                    position: createStartPosition(bSize, GV),
                    turn,
                    GV,
                    size: +bSize,
                    game: true,
                    cleverBot: true,
                }
                isDev() && console.log("set engine", SetupProps, enWorker?.postMessage, white.userId, userId)
                enWorker.postMessage({type: 'setup', data: SetupProps})
                break
            }
            case 'game started': {
                if (engineColor.current === PC.white) {
                    enWorker.postMessage({type: "firstMove"})
                }
                break
            }
            case 'game draw offered': {
                enWorker.postMessage({type: 'draw'})
                // draw.current = false
                break
            }
            case 'game move': {
                move.current = data.payload
                data.payload.position = fullPosition(data.payload.position)
                enWorker.postMessage({type: 'move', data: data.payload})
                break
            }
            default: {
                break
            }
        }
    }
    useEffect(() => {   
        if (localEngine) {
            dispatch(startEngine(false))
        }
        const unsubWs = wsService.$ws.subscribe(setWs)
        const unsubMess = wsService.$message.subscribe(wsMessageHandler)
        return () => {
            unsubMess()
            unsubWs()
            enWorker && enWorker.terminate()
        }
    }, [])

    return <></>}

export default OnlineBot
