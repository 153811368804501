export const I18n: { [key: string]: any } = {
    en: {
        back: "Back",
        hi: "Welcom ",
        yes: "Yes",
        no: "No",
        pieceMoveMenuTitle: 'switch to delete piece',
        pieceRemMenuTitle: 'switch to drag piece',
        firstMoveBMenuTitle: "set turn black",
        firstMoveWMenuTitle: "set turn white",
        clearBoardMenuTitle: "clear board",
        setStartBoardMenuTitle: "set start position",
        setKingMenuTitle: "set kings on",
        remKingMenuTitle: "set kings off",
        gameVarMenuTitle: "choose game variant",
        selectGameMenuTitle: "select game to analyze",
        reverseBoardMenuTitle: "turn board",
        startEngineMenuTitle: "start engine to evaluate position",
        stopEngineMenuTitle: "stop engine",
        userBlockMenuTitle: "block user",
        userFriendMenuTitle: "send freinds request",
        surrenderAndAnalizeTitle: "surrender and analize game",
        analizeTitle: 'analize game',
        lastMoveMenuTitle: "show last move",
        cancelGameMenuTitle: "cancel game",
        newGameMenuTitle: "new game",
        undoMoveMenuTitle: "undo move",
        hintMenuTitle: 'hint',
        offerDrawMenuTitle: "offer draw",
        acceptDrawMenuTitle: "accept draw",
        declineDrawMenuTitle: "decline draw",
        backToPuzzMenuTitle: "back to puzzles menu",
        nextPuzzMenuTitle: "next puzzle",
        connectionTitle: "connection quality",
        langTitle: "language menu",
        rematchLTitle: 'Revanche',
        rematchWTitle: 'Once more',
        closeTitle: "close",
        timer1: "expected time",
        timer2: "passed time",
        timerUnits: 'sec',
        loseMsg: "You lose",
        lastOnbModal: "Customize the application to your tastes. Your name, theme and other settings are up to you. Good luck in the game!",
        skipOnbText: "If you skip this test your starting rating will be low. Do you want to abort?",
        logoutTitle: 'logout',
        loginTitle: 'login',
        homeTitle: 'home page',
        gameTitle: 'game page',
        profileTitle: 'profile page',
        puzzTitle: 'puzzles page',
        settingsTitle: 'settings page',
        aboutTitle: 'about page',
        feedbackNavTitle: "feedback page",
        gameVariant: "choose game variant",
        engine: "Play vs bot",
        player: "Play vs player",
        chooseColor: "Choose piece color",
        towers: "Towers",
        russian: "Russian",
        all: "All",
        puzzleFilter: "Choose puzzle type",
        international: "International",
        analyzeTitle: 'Analysis',
        shortMainTitle: 'Checkers',
        mainTitle: 'Tower Checkers',
        newGame: 'New game',
        submitLog: 'Login',
        submitReg: 'Registration',
        logTitle: 'Login',
        regTitle: 'Registration in',
        logMess: 'Please enter nickname or e-mail and password',
        regMess: 'Please fill registration form',
        modeButtonLog: 'Do not have registered yet?',
        modeButtonReg: 'Sign In',
        labelPassword: 'Password',
        labelNickname: 'Nickname',
        submitButton: 'Submit',
        about_title: "Welcome to Tower Checkers!",
        about_start: "We present one of the most complex and beautiful checkers game. The beauty and complexity of the game lies in its special rule: captured checkers are not removed from the board, but placed under the capturing one, forming a tower. These towers move as one piece, the color and owner of which depend on the color of the top checker. When towers are captured, they lose the top checker, so they can change color and owner, which can turn the game around. Therefore, victory is possible if only one player can block or capture all of the opponent's pieces.",
        play: 'Enjoy awsome game',
        puzzles_home: "Resolve puzzle",
        puzzMenuBack: 'Puzzles menu',
        puzzNext: 'Next puzzle',
        playGame: "Play online",
        botGame: "Play vs bot",
        analysisGame: "Analyze game",
        homeQuestion: 'Have you ever played this game before?',
        invite: 'You can &nbsp;<button type="button" class="sign-btn">Sign In</button>&nbsp; to get full access or continue as &nbsp;<button type="button" class="guest-btn">Guest</button>&nbsp; with confines', 
        signin: 'Full Access',
        forgotPassword: "Forgot password",
        emailForPassword: "Enter your e-mail",
        guest: "Guest Access",
        chatInvAuth: "Sign in to get access to chat",
        nicknameOptions: 'You can change your nickname any time on settings page',
        stepOneTitle: 'Please evaluate your skills',
        confirm: 'Confirm',
        premoveAllowed: "Premove allowed",
        step0Indicator: "Explore skills",
        step1Indicator: "Test puzzles",
        step2Indicator: "Customizing",
        step0che: 'Have you ever played any checkers?',
        step0int: 'Have you ever played international?',
        step0extra: 'How do you rate your rating?',
        step0rus: 'Have you ever played russian?',
        step0tow: 'Have you ever played tower checkers?',
        step1che: 'Do you know rules of any checkers game?',
        step1int: 'Do you know rules of the international?',
        step1rus: 'Do you know rules of the russian?',
        step1tow: 'Have you understood rules of the checkers-towers?',
        stepTwoTitle: 'A few more questions',
        stepLastTitle: "Let's customize the board color and other options.",
        timingTitle1: " minutes per game plus",
        timingTitle2: " extra seconds per move",
        stepPuzzlesTitle: "At last some puzzles",
        stepLastConfirm: "Click this link to go to the settings page",
        puzzleCongrats: ['Awesome', 'Great', 'Fantastic', 'Excellent'],
        gameCongrats: 'Congratulations! You win!',
        drawMsg: 'Draw',
        gameCanceledMsg: 'Game canceled',
        puzzleSorry: 'Opps. Wrong move',
        gameOptions:  'Game options',
        backToPuzzMenu: 'Back to menu',
        presetsInvite: "Without registration you can play only against AI, choosing its strength by rating.",
        presetsInvite2: 'More features will be available after registration!',
        rematch: "Rematch",
        skip: "Skip",
        oops: " Oops. Wrong move",
        rulesKnown1: "barely",
        rulesKnown2: "solidly",
        rulesKnown3: "deeply",
        signToGetPuzzles: 'Sign In to get more puzzles',
        White_takes: "White takes and wins",
        Black_takes: "Black takes and wins",
        Black_to: "Black to move and wins",
        White_to: "White to move and wins",
        Russian: "Russian checkers",
        Towers: "Tower checkers",
        International: "International checkers",
        saveButton: 'Save',
        rating: 'Your game ratings',
        puzzles: 'Your puzzle ratings',
        games: "Games played",
        prevGames: "Prevoius games",
        nextGames: "Next games",
        selectGame: 'Choose game',
        level: "Level",
        appSettings: "App settings",
        mode: "Mode",
        language: "Language",
        volume: "Volume",
        colorSettings: "Color settings",
        theme: "Theme",
        bpc: "'Black' color",
        wpc: "'White' color",
        boardSettings: "Board and pieces settings",
        personalSettings: "Personal settings",
        name: "Name",
        password: "Password",
        arrowHighlight: "Arrow highlight",
        highlightNext: "Highlight next move",
        highlightLast: "Highlight last move",
        notation: "Board notation",
        pieceView: "Piece view",
        highlightPieces: "Highlight Pieces",
        casual: "Casual",
        ranked: "Ranked",
        playBotTitle: "Play vs bot with rating",
        rivalType: "Choose rival type",
        moves: "Moves:",
        black: "Black",
        white: "White",
        timing: "timing",
        unlimited: "unlimited",
        win: "win",
        draw: "draw",
        lose: "lose",
        feedbackTitle: "Please send your feedback",
        subject: "Subject",
        claim: "Claim",
        proposal: "Proposal",
        bugs: "Bugs report",
        other: "Other",
        donation: "Make donation",
        donationMsg: "Your support is vital to the project",
        send: "Send",
        newPasswordOk: 'An email has been sent to set a new password. Please check your email',
        passwordRequirement: "Password must contain one of !&^%$#* charters end have at least length 6"
    },
    ru: {
        back: "Назад",
        hi: "Добро пожадовать ",
        yes: "Да",
        no: "Нет",
        pieceMoveMenuTitle: 'включить удаление шашек',
        pieceRemMenuTitle: 'включить перемещение шашек',
        firstMoveBMenuTitle: "ход черных",
        firstMoveWMenuTitle: "ход белых",
        clearBoardMenuTitle: "очисть доску",
        setStartBoardMenuTitle: "установить начальную позицию",
        setKingMenuTitle: "включить превращение в дамку",
        remKingMenuTitle: "отключить превращение в дамку",
        gameVarMenuTitle: "выберите вариант игры",
        selectGameMenuTitle: "выберите игру",
        reverseBoardMenuTitle: "развернуть доску",
        startEngineMenuTitle: "запустить движок для оценки позиции",
        stopEngineMenuTitle: "остановить движок",
        userBlockMenuTitle: "заблокировать игрока",
        userFriendMenuTitle: "предложить дружбу",
        surrenderAndAnalizeTitle: "сдасться и перейти к анализу игры",
        analizeTitle: 'анализировать игру',
        lastMoveMenuTitle: "показать последний ход",
        cancelGameMenuTitle: "отменить игру",
        newGameMenuTitle: "новая игра",
        undoMoveMenuTitle: "вернуться на ход назад",
        hintMenuTitle: 'подсказать ход',
        offerDrawMenuTitle: "предложить ничью",
        acceptDrawMenuTitle: "принять ничью",
        declineDrawMenuTitle: "отклонить ничью",
        backToPuzzMenuTitle: "вернуться в меню задач",
        nextPuzzMenuTitle: "следующая задача",
        connectionTitle: "качество соединения",
        langTitle: "языковое меню",
        closeTitle: "закрыть",
        rematchLTitle: 'Реванш',
        rematchWTitle: 'Еще партию',
        homeNavTitle: 'на домашнюю страницу',
        timer1: "ожидаемое время",
        timer2: "прошедшее время",
        timerUnits: 'сек',
        loseMsg: "Вы проиграли",
        drawMsg: 'Ничья',
        gameCanceledMsg: 'Игра отмена',
        lastOnbModal: "Настройте приложение по своему вкусу. Ваше имя, тема и другие настройки на ваше усмотрение. Удачи в игре!",
        skipOnbText: "Если вы пропустите этот тест Ваш стартовый рейтинг останется низким. Хотите прервать?",
        logoutTitle: 'выйти',
        loginTitle: 'войти',
        homeTitle: 'домашняя страница',
        gameTitle: 'игра',
        profileTitle: 'профиль',
        puzzTitle: "задачи",
        settingsTitle: 'настройки',
        aboutTitle: 'о проекте',
        feedbackNavTitle: "фидбек",
        shortMainTitle: 'Шашки',
        engine: "Играть с ботом",
        player: "Играть онлайн",
        mainTitle: 'Столбовые Шашки',
        newGame: 'Новая игра',
        submitLog: 'Войти',
        gameVariant: "Выбирете вариант игры",
        chooseColor: "Выбирете цвет шашек",
        towers: "Столбовые",
        russian: "Русские",
        all: "Все",
        international: "Международные",
        submitReg: 'Зарегистрироваться',
        logTitle: 'Для входа в',
        regTitle: 'Для регистрации в',
        logMess: 'Пожалуйста введите свое имя или email и пороль',
        regMess: 'Пожалуйста заполните регистрационную форму',
        modeButtonLog: 'Еще не зарегистрированы?',
        modeButtonReg: 'Войти в аккаунт',
        labelPassword: 'Пароль',
        labelNickname: 'Игровой ник или email',
        submitButton: 'Отправить',
        about_title: "Добро пожаловать на Столбовые Шашки!",
        about_start: 'Это — одна из самых сложных и красивых шашечных игр. Ее красота и сложность заключается в особом правиле: побитые шашки не снимаются с доски, а ставятся под бьющую, образуя башню. Эти башни ходят как одна фигура, цвет и владелец которой зависит от цвета верхней шашки. Если башня попадет под бой она теряет верхнюю шашку, поэтому может сменить цвет и хозяина, что может перевернуть ход игры. Поэтому победа возможна, если только один из игроков сможет заблокировать или захватить все шашки противника.',
        play: 'Играй и получай удовольствие от восхитительной игры',
        puzzles_home: "Решить задачку",
        playGame: "Играть онлайн",
        botGame: "Играть с ботом",
        premoveAllowed: "Разрешить предход",
        analysisGame: "Анализировать игру",
        step0Indicator: "Игровые навыки",
        step1Indicator: "Тестовые задачи",
        step2Indicator: "Настройки",
        homeQuestion: 'Have you ever played this game before?',
        invite: 'Вы можете &nbsp;<button type="button" class="sign-btn">Войти</button>&nbsp; чтобы получить полный доступ или продолжить как &nbsp;<button type="button" class="guest-btn">Гость</button>&nbsp; с ограничениями', 
        signin: 'Полный доступ',
        puzzles: 'Ваши рейтинги задач',
        games: "Сыграно игр",
        guest: "Доступ гостя",
        chatInvAuth: "Войдите чтобы получить доступ к чату",
        nicknameOptions: 'Вы можете изменть свое имя в любой момент в настройках',
        stepOneTitle: 'Оцените свои игровые навыки',
        confirm: 'Подтвердить',
        step0che: 'Играли ли вы когда-нибудь в какие-либо шашки?',
        step0int: 'Играли ли вы когда-нибудь в международные?',
        step0extra: 'Как вы оцениваете ваш рейтинг?',
        step0rus: 'Играли ли вы когда-нибудь в русские?',
        step0tow: 'Играли ли вы когда-нибудь в столбовые?',
        step1che: 'Знакомы ли вы с правилами какой-либо шашечной игры?',
        step1int: 'Знакомы ли вы с правилами международных шашек?',
        step1rus: 'Знакомы ли вы с правилами русских шашек?',
        step1tow: 'Понимаете ли вы правила столовых шашек?',
        stepTwoTitle: 'Еще несколкьо вопросов',
        // stepLastTitle: "Давайте настроим цвет доски, шашек и другие опции игры",
        stepPuzzlesTitle: "Наконец несколко пазлов",
        stepLastConfirm: "Нажмите на ссылку, чтобы перейти на страницу настроек",
        puzzleCongrats: ['Замечательно', 'Превосходно', 'Великолепно'],
        puzzleSorry: 'Упс. Неверный ход',
        gameOptions:  'Настройки игры',
        backToPuzzMenu: 'Назад к меню',
        presetsInvite: "Без регистрации вы можете играть только против ИИ, выбирая его силу по рейтингу.",
        presetsInvite2: "Больше опций будет доступно после регистрации",
        rematch: "Rematch",
        skip: "Пропустить",
        emailForPassword: "Введите ваш e-mail",
        oops: " Упс. Неверный ход",
        rulesKnown1: "едва",
        rulesKnown2: "неплохо",
        rulesKnown3: "глубоко",
        signToGetPuzzles: 'Войдите, чтобы получть новые задачи',
        White_takes: "Белые бьют и побеждают",
        Black_takes: "Черные бьют и побеждают",
        Black_to: "Черные начинают и выигрывают",
        White_to: "Белые начинают и выигрывают",
        Russian: "Русские шашки",
        Towers: "Столбовые шашки",
        International: "Международные шашки",
        saveButton: 'Сохранить',
        prevGames: "Предыдущие игры",
        nextGames: "Последующие игры",
        selectGame: 'Выберити игру',
        gameCongrats: 'Поздравлем! Вы победили!',
        level: "уровень",
        puzzleFilter: "Выберите тип пазлов",
        rating: "Ваши игровые рейтинги",
        forgotPassword: "Забыли пороль",
        appSettings: "Настройки приложения",
        mode: "Вид",
        language: "Язык",
        volume: "Громкость",
        colorSettings: "Настройка темы",
        theme: "Тема",
        bpc: "'Черный' цвет",
        wpc: "'Белый' цвет",
        boardSettings: "Настройки доски и шашек",
        personalSettings: "Личные данные",
        name: "Имя",
        password: "Пароль",
        arrowHighlight: " Подсветка стрелкой",
        highlightNext: "Подстветка текущего хода",
        highlightLast: "Подсветка последнего хода",
        notation: "Нотация доски",
        pieceView: "Вид шашки",
        highlightPieces: "Подсветить шашки",
        playBotTitle: "Играть с ботом с рейтингом",
        timingTitle1: " минут на игру",
        timingTitle2: " добавление секунд за ход",
        casual: "Дружеская",
        ranked: "Рейтинговая",
        rivalType: "Выбирете тип соперника",
        moves: "Ходы:",
        black: "Черные",
        white: "Белые",
        timing: "время",
        unlimited: "неограничено",
        win: "победа",
        draw: "ничья",
        lose: "поражение",
        feedbackTitle: "Пожалуйста поделитесь своим мнением",
        subject: "Предмет",
        claim: "Претензия",
        proposal: "Предложение",
        other: "Другое",
        bugs: "Сообщение о баге",
        donation: "Сделать пожертвование",
        donationMsg: "Ваша поддержка жизненно важна для проекта",
        send: "Отправить",
        newPasswordOk: 'Письмо для смены пороля отправлено вам на почту. Пожалуйста проверте свой email',
        passwordRequirement: "Пароль должен содержать хотя бы один из символов !&^%$#* не содержать пробелов и состоять не менее чем из 6 знаков"
    }
}
