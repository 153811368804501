

import Navbar from './Navbar'

import './header.scss'
import { NavLink } from 'react-router-dom'
import { I18n } from '../../assets/i18n'
import { Logo } from '../Logo/LogoIcon'
import { RoutePaths } from '../../constants/gameConstants'
import Engine from '../../local-engine/AIEngine'
import { useSelector } from 'react-redux'
import { IRootState } from '../../models/models'
import { selectLanguage, selectMode, selectTheme } from '../../store/userSlice'
import React from 'react'
import { selectWindowSize } from '../../store/topStateSlice'


export const Header = React.memo(() => { 
    const localEngine = useSelector((state: IRootState) => state.topState.localEngine)
    const isDevice = useSelector((state: IRootState) => state.topState.isDevice)
    const winSize = useSelector(selectWindowSize)
    const mode = useSelector(selectMode)
    const theme = useSelector(selectTheme)
    const ln = useSelector(selectLanguage)
    const i18n = I18n[ln.slice(0, 2)]
    const name = useSelector((state: IRootState) => state.user.name)
    const onbPassed = useSelector((state:IRootState) => state.user.exp.onboardingPassed)
    return (
        <>
            <header className={"header noselect mode-" + mode + " theme-" + theme}>
                <NavLink title={i18n.homeTitle} to={RoutePaths.home} className="brand-logo">
                    <Logo size={21} />
                    <span>{I18n['en'].mainTitle}</span>
                </NavLink>
                {(isDevice || winSize.width < 500) && onbPassed 
                    ? <span className='user-name'>{name}</span> 
                    : null
                }
                <Navbar />
                {localEngine ? <Engine /> : null}
            </header>
            <div className="header-back" />
        </>
    )
})
