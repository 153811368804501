import { delay, put, select, takeLatest } from 'redux-saga/effects'
import {
    GameResult,
    IGameState,
    IRootState,
    PieceColor,
} from '../../models/models'

import { setPremove, setTouchedTower, updateBoardState} from '../boardSlice'
import { EngineLevels } from '../../constants/gameConstants'
import { createStartPosition, getPlayerColor, updateCellsMap } from '../../local-engine/board-helper-fn'
import { sendWsMessage, showAds, startEngine } from '../topStateSlice'
import { moveR } from '../../local-engine/move-r'

import { 
    confirmGameStarted,
    endGame,
    InitialGameState,
    createChallenge,
    newGameVSPC,
    newGameVSPlayer,
    setGameStarted,
    setGameState,
} from '../gameStateSlice'
import { InitialGame, setNextMoves, setStartPosition, updateGame } from '../gameSlice'
import { updatePiecesPosition } from '../../local-engine/board-helper-fn'
import { copyObj, getNewExp, isDev, ratingCalc } from '../../local-engine/gameplay-helper-fn'
import { UnknownAction } from 'redux'
import { saveUser, updateExp } from '../userSlice'
import storage from '../../common/storage'
import { InitialAnalysisState, updateAnalysisState } from '../analysisSlice'
import clocksService from '../../common/clock-service'


function* workerNewGameVsPlayer(action: UnknownAction) {
    const {
        user: {userId},
        board: {cellsMap, boardSize: bs, cellSize}
    } = (yield select()) as IRootState
    const {timing, white, black, gameKey, bSize, GV} = action.payload as any
    moveR.setProps({GV, size: +bSize})
    let position = createStartPosition(+bSize, GV)
    const playerColor = white.userId === userId ? PieceColor.white : PieceColor.black
    const reversedBoard = playerColor === PieceColor.black
    const [gameTimeLimit, adds] = timing.split('/').map((t: string) => parseInt(t))
    const gamePayload: Partial<IGameState> = {
        gameKey,
        black,
        white,
        whiteClock: gameTimeLimit * 60,
        blackClock: gameTimeLimit * 60,
        waitingForRival: false,
        gameConfirmed: false,
        gameStarted: false,
        playerColor,
    }
    yield put(setGameState({...InitialGameState, ...gamePayload}))
    const cMap = updateCellsMap({cellsMap, cellSize, boardSize: bs, reversedBoard})
    yield put(updateBoardState({cellsMap: cMap, cellSize, reversedBoard}))
    position = updatePiecesPosition(position, cMap, cellSize)
    yield put(updateGame({...InitialGame, position, startPosition: position}))
    yield put(updateAnalysisState({...InitialAnalysisState}))
    yield put(sendWsMessage({
        message: "game ready to play", 
        payload:{
            [`${playerColor.charAt(0)}Ready`]: 1, 
            gameKey
        },
        from: userId
    }))
    clocksService.setProps({
        white: gamePayload.whiteClock!, black: gamePayload.blackClock!, adds
    })
}

function* workerNewGameVsPC() {
    const {
        gameSettings: { selectedColor, rivalLevel = 1, competitionType: cT, gameVariant: GV },
        user: { name, ratings},
        topState: {localEngine},
        board: {boardSize: bs, cellsMap, cellSize},
    } = (yield select()) as IRootState
    isDev() && console.log('creting new game vs PC', selectedColor, rivalLevel, cT)
    const playerColor = getPlayerColor(selectedColor)
    let position = createStartPosition(bs, GV)
    const rating = ratings[GV.slice(0, 3)][ratings[GV.slice(0, 3)].length -1].rating
    const rival = { name: `Bot-${rivalLevel}` }
    const reversedBoard = playerColor === PieceColor.black
    const rRating = EngineLevels[rivalLevel]
    const changes = cT === 'casual' ? [0,0,0] : ratingCalc(rating, rRating)
    const invertChanges = cT === 'casual' ? [0,0,0] : ratingCalc(rRating, rating)
    const white =
        playerColor === PieceColor.white
            ? { name, rating, ratingChanges: changes }
            : { name: rival.name, rating: rRating, ratingChanges: invertChanges}
    const black =
        playerColor === PieceColor.white
            ? { name: rival.name, rating: rRating, ratingChanges: invertChanges }
            : { name, rating, ratingChanges: changes}
    const gamePayload: Partial<IGameState> = {
        gameKey: `game-vs-${rival.name}`,
        playerColor,
        white,
        black,
        gameConfirmed: false,
        waitingForRival: false,
        gameStarted: false,
    }
    yield put(setGameState({...InitialGameState, ...gamePayload}))
    const cMap = updateCellsMap({cellsMap, cellSize, boardSize: bs, reversedBoard})
    yield put(updateBoardState({cellsMap: cMap, cellSize, reversedBoard}))
    position = updatePiecesPosition(position, cMap, cellSize)
    yield put(updateGame({...InitialGame, position, startPosition: position}))
    yield put(setStartPosition(position))
    yield put(updateAnalysisState(InitialAnalysisState))
    yield delay(200)
    yield put(setGameStarted(true))
    if (localEngine) {
        yield put(startEngine(false))
        yield delay(0)
    }
    yield put(startEngine(true))
}

function* workerChallenge(action: UnknownAction) {
    if (!action.payload) return
    const {gameState: {playerOnline}} = (yield select()) as IRootState
    yield put(setGameState({...InitialGameState, waitingForRival: true, playerOnline}))
    yield put(setTouchedTower(null))
    yield put(setPremove(null))
    const {
        gameSettings: {
            selectedColor,
            timing: {gameTimeLimit, adds}, 
            gameVariant: GV,
            rivalType,
            competitionType
        },
        board: {boardSize: bSize},
        user: { ratings, name, userId }
    } = (yield select()) as IRootState
    if (rivalType === 'engine') {
        yield delay(0)
        yield workerNewGameVsPC()
        return
    }
    const payload = {
        bSize, 
        sColor: selectedColor, 
        timing: `${gameTimeLimit}/${adds}`, 
        GV, 
        rating: ratings[GV.slice(0, 3)][ratings[GV.slice(0, 3)].length - 1].rating,
        name,
        userId,
        cType: competitionType
    }
    const message = "challenge"
    try {
        yield put(sendWsMessage({message, payload, from: userId}))
    } catch(e) {
        console.error(e)
    }
}

function* workerEndGame(action: UnknownAction) {
    const {
        gameSettings: {competitionType, rivalType, gameVariant: GV, rivalLevel}, 
        gameState,
        user: {userId, ratings, exp, games = []},
        topState: {localEngine}
    } = (yield select()) as IRootState
    if (localEngine) yield put(startEngine(false))
    if (rivalType === 'player') clocksService.stopGame()
    yield delay(rivalType === 'engine' ? 50 : 200)
    let {game, white = gameState.white, black = gameState.black, playedAt} = action.payload as any
    isDev() && console.log('game end', game.result, white, black, rivalType, competitionType)
    if (competitionType === 'ranked' && rivalType === 'player') {
        const nRatings = copyObj(ratings)
        nRatings[GV.slice(0, 3)].push({
            rating: (userId === white.userId ? white.rating : black.rating),
            date: playedAt
        })
        // console.log('new ratins', nRatings)
        yield put(saveUser({ratings: nRatings, games: [...games.slice(1), game]}))
        storage.saveUser({ratings: nRatings, games})
    } else if (competitionType === 'ranked') {
        const res = (gameState.playerColor === PieceColor.white || game.result === GameResult.draw)
            ? game.result
            : game.result.split('').reduceRight((acc: string, i: string) => acc + i, '')
        const nExp = getNewExp(GV, exp, rivalLevel, res)
        yield put(updateExp(nExp))
        storage.saveUser({exp: nExp})
    }
    yield put(setGameState({
        ...gameState,
        white,
        black,
        gameConfirmed: false,
        gameStarted: false,
        result: game.result
    }))
    yield delay(0)
    yield put(showAds(true))
}

function* workerGameStarted(action: UnknownAction) {
    const {
        gameSettings: {rivalType},
        gameState: {playerColor, gameKey},
        game: {turn, position: pos},
        board: {cellSize, cellsMap}
    } = (yield select()) as IRootState
    if (!gameKey) return
    isDev() && console.log('game started', action, turn, playerColor)
    if (turn === playerColor) {
        const position = updatePiecesPosition(pos, cellsMap, cellSize)
        const nextMoves = moveR.getPossibleMoves(position, PieceColor.white)
        yield put(setNextMoves(nextMoves))
    }
    if (rivalType === 'engine') {
        yield put(confirmGameStarted(true))
        return
    }
    clocksService.startClock(turn)
}

export default function* watcherGameState() {
    yield takeLatest(newGameVSPlayer, workerNewGameVsPlayer)
    yield takeLatest(newGameVSPC, workerNewGameVsPC)
    yield takeLatest(setGameStarted, workerGameStarted)
    yield takeLatest(createChallenge, workerChallenge)
    yield takeLatest(endGame, workerEndGame)
}
