import { useDispatch, useSelector} from "react-redux"
import { TiLightbulb } from "react-icons/ti";
import { MdOutlineAutorenew } from "react-icons/md";
import { CgMenuGridR } from "react-icons/cg";

import { help } from '../../store/gameSlice'
import { turnBoard } from "../../store/boardSlice";
import { 
    selectBoardWithProps, selectPuzzle, selectUserWithProps, selectWithProps 
} from "../../store/rootState&Reducer";
import { IPuzzle, IRootState, ITopState } from "../../models/models";

import { TbPuzzle } from "react-icons/tb";
import { choosePuzzle } from "../../store/topStateSlice";
import { useNavigate } from "react-router-dom";
import { TopState } from "../../constants/gameConstants";
import { useEffect, useState } from "react";
import { I18n } from "../../assets/i18n";


export const PuzzleMenu = () => {
    const {reversedBoard: revBoard} = useSelector(selectBoardWithProps(['reversedBoard']))
    const {
        puzzleResolved: passed,
        ads
    } = useSelector(selectWithProps(['puzzleResolved', 'ads'], TopState)) as ITopState
    const {exp: {onboardingPassed}, language: ln} = useSelector(
        selectUserWithProps(['exp', 'language'])
    )
    const puzzle = useSelector(selectPuzzle)
    const {puzzles: ps, selectedPuzzle} = useSelector(
        selectWithProps(['puzzles', 'selectedPuzzle'], TopState)
    ) as ITopState
    const i18n = I18n[ln.slice(0, 2)]
    const [diasbled, setDiasbled] = useState(passed && !passed.animated)
    const puzzles = ps.slice().sort((a: IPuzzle, b: IPuzzle) => a.level - b.level)
    const navi = useNavigate()
    const dispatch = useDispatch()
    const hintMove = useSelector((state: IRootState) => state.game.hintMove)
    const noHint = !hintMove && puzzle?.level > 6

    useEffect(() => {
        if (!passed) return
        setDiasbled(!passed.animated)
    }, [passed])

    const handleBack = () => {
        if (diasbled || ads) return
        dispatch(choosePuzzle(''))
    }
    const handleNext = () => {
        if (diasbled || ads) return
        const next = puzzles.find((p: IPuzzle) => p._id !== selectedPuzzle) as IPuzzle
        dispatch(choosePuzzle(next._id))
        navi(`../${next._id}`)
    }
    const turnBoardOver = () => {
        if (diasbled) return
        dispatch(turnBoard(!revBoard))
    }

    const handleHelp = () => {
        if (noHint || diasbled) return
        dispatch(help(true))
    }

    const hintClass = `puzzle-menu__item hint${noHint || diasbled  ? ' disabled' : ''}${puzzle?.level <= 3 ? ' anim' : ''}`
    const liClass = `puzzle-menu__item${diasbled ? ' disabled' : ''}`
    return (
        <ul className="puzzle-menu">
            {
                onboardingPassed 
                ? <>
                        <li
                            className={liClass}
                            onClick={handleBack}
                            role="button"
                            title={i18n.backToPuzzMenuTitle}
                        >
                            <CgMenuGridR />
                        </li>
                        <li
                            className={liClass}
                            onClick={handleNext}
                            role="button"
                            title={i18n.nextPuzzMenuTitle}
                        >
                            <TbPuzzle />
                        </li>
                    </>
                : null
            }
            <li 
                title={i18n.reverseBoardMenuTitle} 
                role="button" 
                className={`${liClass} revert`}
                onClick={turnBoardOver}
            >
                <MdOutlineAutorenew />
            </li>
            {
                onboardingPassed
                ? null
                : <li 
                    title={i18n.hintMenuTitle} 
                    role="button" 
                    className={hintClass} 
                    onClick={handleHelp}
                >
                    <TiLightbulb />
                </li>
            }
                            
        </ul>
    )
    
}
