import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectWindowSize } from "../store/topStateSlice"

export const useDevice = () => {
    const [device, setDevice] = useState(false)
    const winWidth = useSelector(selectWindowSize).width
    useEffect(() => {
        if (winWidth > 900) {
            setDevice(false)
        } else if (winWidth <= 900) {
            setDevice(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [winWidth])
    return device
}

export default useDevice