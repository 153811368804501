import {
    IPositionData,
    PieceColor as PC,
    MMRResult, 
    IPieces,
} from '../models/models'
import MoveResolver from './move-resolver'
import { copyObj, normaFn, oppositeColor, sumMoves } from './gameplay-helper-fn'
import { 
    TopLegendValues, DefaultData, EvValLim 
} from '../constants/gameConstants'


class Evaluator {
    moveR: MoveResolver
    positionData = {
        white: copyObj(DefaultData),
        black: copyObj(DefaultData),
    } as IPositionData

    constructor(moveRes: MoveResolver) {
        this.moveR = moveRes
    }

    resetData() {
        this.positionData = {
            white: copyObj(DefaultData),
            black: copyObj(DefaultData),
        } as  IPositionData
    }
    
    getPiecePositionValue = (pieces: IPieces, key: string) => {
        const {color} = pieces[key]
        const lineValue =
            color === PC.white
                ? parseInt(key.slice(1)) / this.moveR.size
                : (this.moveR.size + 1 - parseInt(key.slice(1))) / this.moveR.size
        return (lineValue) / this.moveR.size + 1
    }

    getTowerPositionValue = (towers: IPieces, key: string) => {
        const {color} = towers[key]
        const tL = TopLegendValues.slice(0, this.moveR.size)
        const index = tL.indexOf(key[0])
        const sign = index < 3 && index > this.moveR.size - 3 ? -1 : 1
        const tP = towers[key][color]
        const bP = towers[key][oppositeColor(color)]
        return 1 + sign* (normaFn(tP - bP) / (bP + tP)) / 4
    }

    evalKing(key: string, towers: IPieces) {
        if (!towers[key]) {
            console.error('incorrect pos evaluation', key, towers)
            return 0
        }
        const {color} = towers[key]
        if (this.moveR.checkKingCase(key, color)) return 0
        return this.moveR.evaluateKing(key, color, this.positionData[oppositeColor(color)].pk)
    }

    evalKings = (towers: IPieces) => {
        const {white: {kings: wKs}, black: {kings: bKs}} = this.positionData
        switch (true) {
            case !wKs.length || !bKs.length: {
                return wKs.reduce((acc, k) => {acc += this.evalKing(k, towers); return acc}, 0) 
            }
            default: return 0
        }
    }

    getPositionData = (towers: IPieces, turn: PC, extra = false) => {
        this.resetData()
        let mandatory = [] as MMRResult[], 
            free = [] as MMRResult[], 
            rMandatory = [] as MMRResult[], 
            rFree = [] as MMRResult[]
        for (let key in towers) {
            this.savePieceData(towers, key)
            if (towers[key].color !== turn) {
                const _moves = this.moveR.getPieceMoves(towers,  key, true)
                const [m, f] = sumMoves(_moves, rMandatory, rFree)
                rMandatory = m; rFree = f
                continue
            }
            const _moves = this.moveR.getPieceMoves(towers,  key)
            const [m, f] = sumMoves(_moves, mandatory, free)
            mandatory = m; free = f
        }
        this.positionData[turn].moveNumber = mandatory.length + free.length
        this.positionData[oppositeColor(turn)].moveNumber = rMandatory.length + rFree.length
        const moves = this.moveR.getPossibleMovesForTree(mandatory.length ? mandatory : free)
        const value = this.getPositionValue(towers)
        if (Math.abs(value) > EvValLim) {throw new Error('invalid val')}
        return !extra 
            ? {
                moves,
                deepValue: { depth: 0, value, move: '' }
            }
            : {
                deepValue: { depth: 0, value, move: '' },
                na: this.advantageInNumberOfMoves(),
                pa: this.advantageInPieces(),
                ta: this.advantageInTowers(),
                kv: this.evalKings(towers),
                pdW: this.positionData.white,
                pdB: this.positionData.black,
                mad: this.materialAdvantage()
            }
    }

    getPositionValue = (towers: IPieces) =>  {
        let kingsExtraVal = 0
        if (this.positionData.white.kings.length || this.positionData.black.kings.length) {
            kingsExtraVal = this.evalKings(towers)
        }
        const advInMoves = this.advantageInNumberOfMoves()
        const matAdv = this.materialAdvantage()
        // const advInPices = this.advantageInPieces()
        // const advInTowers = this.advantageInTowers()
        // const val = advInPices + advInMoves + advInTowers
        return Math.floor(normaFn(advInMoves + matAdv + kingsExtraVal) * 300) * EvValLim / 1000
    }

    avaluatePiece = (towers: IPieces, key: string) => {
        const piecePositionValue = this.getPiecePositionValue(towers, key)
        const { king, color } = towers[key]
        const pieceValue = king ? 2.2 : piecePositionValue
        this.positionData[color].pieces += pieceValue
        if (king) {this.positionData[color].kings.push(key)}
        else {this.positionData[color].pk.push(key)}
    }

    evaluateTower = (towers: IPieces, key: string) => {
        const {color, king} = towers[key]
        const pieceValue = this.getPiecePositionValue(towers, key)
        let towerValue = this.getTowerPositionValue(towers, key)
        if (king) { this.positionData[color].kings.push(key)}
        else {this.positionData[color].pk.push(key)        }
        this.positionData[color].pieces += king ? 2.2 : pieceValue*.2
        const topV = (king ?  1 : pieceValue * towerValue) * towers[key][color] *
            (1 + normaFn(towers[key][color]) * towers[key][color] / 2)
        this.positionData[color].towersT += topV
        const bottomV = this.bottomTowersValue(topV, towers[key][oppositeColor(color)], king)
        this.positionData[oppositeColor(color)].towersB += bottomV
        return [topV, bottomV]        
    }

    advantageInNumberOfMoves = ()  => {
        const {
            white: {moveNumber: wM, kings: wK}, black: {moveNumber: bM, kings: bK}
        } = this.positionData
        return ((wK.length + bK.length < 1) ? 1 : .1) * (2.2 / bM - 2.2 / wM)
    }

    bottomTowersValue = (tP: number, bP: number, king = false) => {
        if (!king) {
            return bP * (.6 / tP)
        } else {
            return bP * (.2 / tP)
        }
    }

    advantageInTowers = () => {
        const {white, black} = this.positionData
        const tFactorW = 1 + normaFn(white.pieces - black.pieces) / 8
        const tFactorB = 1 - normaFn(white.pieces - black.pieces) / 8
        const whiteT =(white.towersT + white.towersB) * tFactorW
        const blackT = (black.towersT + black.towersB) * tFactorB
        return whiteT - blackT 
    }

    materialAdvantage = () => {
        const {
            white: {pieces: wP, towersT: wT, towersB: wB}, 
            black: {pieces: bP, towersT: bT, towersB: bB}
        } = this.positionData
        const wM = wP + wT
        const bM = bP + bT
        this.positionData.white.value = (wM + (wM ? wB : 0)) * (wM + .1) / (.1 + bM)
        this.positionData.black.value = (bM + (bM ? bB : 0))
        return this.positionData.white.value - this.positionData.black.value 
    }

    advantageInPieces = () => {
        const {
            white: {kings: wK, pieces: wP}, 
            black: {kings: bK, pieces: bP}
        } = this.positionData
        const wKingsCorr = wK ? (5 - bP) / 8 : 0
        const bKingsCorr = bK ? (5 - wP) / 8 : 0
        return (wP + wKingsCorr - bP - bKingsCorr) * 1.2
    }

    savePieceData = (towers: IPieces, key: string) => {
        if (towers[key].white + towers[key].black > 1) {
            this.evaluateTower(towers, key)
        } else {
            this.avaluatePiece(towers, key)
        }
    }
}

export default Evaluator
