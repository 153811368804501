import { useEffect, useRef } from "react"

import './fade-in.scss'

export const FadeIn = (props: {children: React.ReactNode}) => {
    const fadeRef = useRef(null)
    const fadded = useRef(false)
    
    useEffect(() => {
        if (fadded.current || !fadeRef.current) return
        fadded.current = true
        const classList = (fadeRef.current as HTMLElement).classList
        setTimeout(() => classList.add('fade-in'))
    }, [])
    return <div className="fade-wrapper" ref={fadeRef}>{props.children}</div>
}
