
import { createSlice } from "@reduxjs/toolkit";
import { PieceColor, IPlayer, GameResult, IGameState } from "../models/models";

export const InitialGameState: IGameState = {
    gameKey: '',
    gameConfirmed: false,
    gameStarted: false,
    playerColor: PieceColor.white,
    whiteClock: 0,
    blackClock: 0,
    white: {} as IPlayer,
    black: {} as IPlayer,
    rivalOfferedDraw: false,
    drawOffered: false,
    result: null as unknown as GameResult,
    playerOnline: 0,
    rivalOffline: 0,
    waitingForRival: false,
    // opponentFound: false,
}

const gameStateSlice = createSlice({
    name: 'gameState',
    initialState: InitialGameState,
    reducers: {
        rivalDisconnected(state, action) {state.rivalOffline = action.payload},
        setGameStarted(state, action) { state.gameStarted = action.payload },
        setGameState(state, action) { return {...state, ...action.payload}},
        endGame(state, action) {},
        confirmGameStarted(state, action) {state.gameConfirmed = action.payload},
        cancelGame(state) {
            state.gameConfirmed = false 
            state.gameStarted = false
            state.result = GameResult.canceled
        },
        setWClock(state, action) {state.whiteClock = action.payload},
        setBClock(state, action) {state.blackClock = action.payload},
        declineDraw(state) {state.rivalOfferedDraw = false},
        drawDeclined(state) {state.drawOffered  = false},
        acceptDraw() {},
        setGameKey(state, action) {state.gameKey = action.payload},
        offerDraw(state, action) {state.drawOffered = action.payload},
        rivalOfferedDraw(state) {state.rivalOfferedDraw = true},
        saveResult(state, action) {state.result = action.payload},
        newGameVSPC() {},
        newGameVSPlayer(action) {},
        setPlayerOnlineStatus(state, action) {state.playerOnline = action.payload},
        createChallenge(state, action) {state.waitingForRival = action.payload},
        requestRevenge() {},
        cancelChallenge(state) {state.waitingForRival = false}
    }
})

export const {actions} = gameStateSlice

export const {
    rivalDisconnected,
    endGame,
    offerDraw,
    rivalOfferedDraw,
    declineDraw,
    cancelGame,
    confirmGameStarted,
    saveResult,
    setGameStarted,
    setGameState,
    newGameVSPlayer,
    newGameVSPC,
    requestRevenge,
    createChallenge,
    setPlayerOnlineStatus,
    cancelChallenge,
    acceptDraw,
    drawDeclined,
    setBClock,
    setWClock,
    setGameKey
} = actions

export default gameStateSlice.reducer
