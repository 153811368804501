import React from 'react';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import { createRoot } from 'react-dom/client';

import store from './store'
import { checkStorage } from './store/userSlice';

import App from './App';


import './assets/scss/style.scss'
import { getNewPuzzles, setDevice } from './store/topStateSlice';

const root = createRoot(document.getElementById("root")!);

root.render(
  <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
  </Provider>
);

if (navigator.maxTouchPoints > 1) {
  store.dispatch(setDevice(true))
}

store.dispatch(checkStorage())

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
