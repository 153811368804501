import { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { GrHomeOption } from "react-icons/gr";
import { MdOutlinePerson4 } from 'react-icons/md'
import { RiFeedbackLine } from "react-icons/ri";
import { RxTransparencyGrid } from 'react-icons/rx';
import { MdOutlineSettings } from "react-icons/md";
import { TbInfoSquare, TbLogin, TbLogout } from "react-icons/tb";
import { PiMagnifyingGlassPlusBold } from "react-icons/pi";
import { TbPuzzle2 } from "react-icons/tb";
import { FiMenu }  from 'react-icons/fi'

import { RoutePaths } from '../../constants/gameConstants'
import useCloseEffect from '../../hooks/useCloseEffect'
import useDevice from '../../hooks/useDevice'
import { LanguageSwitcher } from '../CtrlBtns/CtrlBtn'
import { logout, selectLanguage } from '../../store/userSlice'
import { IRootState } from '../../models/models'
import { I18n } from '../../assets/i18n';


const mapState = (state: IRootState) => ({
    token: state.user.token, 
    name: state.user.name,
    isDevice: state.topState.isDevice,
    winSize: state.topState.windowSize,
    onbPassed: state.user.exp.onboardingPassed,
    ln: state.user.language
})

const mapDispatch = {logout}

const connector = connect(mapState, mapDispatch)

type Props = ConnectedProps<typeof connector>

const LoginBarComponent = (props: Props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const ln = useSelector(selectLanguage)
    const i18n = I18n[ln.slice(0, 2)]
    const {logout, name} = props
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const addListeners = useCloseEffect('user-menu-dropdown-toggle', setDropdownOpen, true)
    const handleLogout = (e: any) => {
        e.preventDefault()
        logout();
        setDropdownOpen(false)
        const prepath = location.pathname.split('/').slice(1).map(n => '../').join('')
        navigate(`${prepath}${RoutePaths.auth}`)
    }
    const onClick = () => {
        setDropdownOpen(!dropdownOpen)
        addListeners(true)
    }
    return (
        <div className='user-menu-wrapper is-auth'>
            <button 
                className="user-menu-dropdown-toggle"
                type="button" 
                title={i18n.profileTitle}
                onClick={onClick}
            >
                {name}
            </button>
            <ul className={`user-menu-dropdown${dropdownOpen ? ' open' : ' hidden'}`} >
                <li className='width-maker'><button>{name}</button></li>
                <li title={i18n.logoutTitle} className="user-menu-logout">
                    <button 
                        className='navlink' 
                        type="button"
                        onClick={handleLogout}
                        onTouchStart={handleLogout}
                    >
                        <TbLogout />
                    </button>
                </li>
            </ul>
        </div>
    )
    
}

export const LoginBar = connector(LoginBarComponent)

export const Navbar = (props: Props) => {
    const {logout, token, name, isDevice, onbPassed, winSize, ln} = props
    const [isAuth, setIsAuth] = useState(!!token)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const device = useDevice()
    const i18n = I18n[ln.slice(0, 2)]
    const addListeners = useCloseEffect('navbar-device-menu-btn', setDropdownOpen, device)
    const externalLogBar = !((isDevice || winSize.width < 500) && onbPassed)
    const handleLogout = (e: any) => {
        e.preventDefault()
        logout();
        setDropdownOpen(false)
        const prepath = location.pathname.split('/').slice(1).map(n => '../').join('')
        navigate(`${prepath}${RoutePaths.auth}`) 
    }
    useEffect(() => {
        if (!device && !dropdownOpen) {
            setDropdownOpen(true)
        } else if (device && dropdownOpen) {
            setDropdownOpen(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device])

    useEffect(() => {
        setIsAuth(!!token)
    }, [token])

    useEffect(() => {
        setDropdownOpen(false)
    }, [location.pathname])
    
    const className = `${dropdownOpen ? 'device-menu' : 'hidden'}`
    const liClass = `navlink${!name ? ' no-name' : ''}`
    const AuthMenu = () => {
        return (
            isAuth
            ?  <li title={i18n.logoutTitle} className="user-menu-logout">
                <button 
                    className='navlink' 
                    type="button"
                    onClick={handleLogout}
                    onTouchStart={handleLogout}
                >
                    <TbLogout />
                </button>
            </li>
            : <li className={`user-menu-login ${name ? '' : ' highlight'}`}>
                <NavLink className={`navlink ${name ? '' : ' highlight'}`} 
                    title={i18n.loginTitle} 
                    to={RoutePaths.auth}
                >
                    <TbLogin />
                </NavLink> 
             </li>           
        )
    }
    return (
        <div className={`nav-wrapper`} >
        {!onbPassed ? <LanguageSwitcher /> : null}
        <nav className={`navbar${onbPassed ? '' : ' no-name'}`}>
            <button
                className={`navbar-device-menu-btn${!device ? ' hidden' : ''}`}
                type="button"
                onClick={() => {setDropdownOpen(!dropdownOpen); addListeners(true);}}
                aria-controls="navbarContent"
                aria-expanded={dropdownOpen ? "true" : "false"}
                aria-label="Toggle navigation"
            >
                <FiMenu />
            </button>
            <ul 
                className={`${!device ? '' : className}`}
                id="navbarContent"
            >
                <li title={i18n.homeTitle}>
                    <NavLink className={'navlink'} to={RoutePaths.home} >
                        <GrHomeOption />
                    </NavLink>
                </li>
                {
                    name && onbPassed
                        ? <>
                            <li title={i18n.aboutTitle}>
                                <NavLink className={liClass} to={RoutePaths.about} >
                                    <TbInfoSquare />
                                </NavLink>
                            </li>
                            <li title={i18n.puzzTitle}>
                                <NavLink className={liClass} to={RoutePaths.puzzles} >
                                    <TbPuzzle2 />
                                </NavLink>
                            </li>
                            <li title={i18n.gameTitle}>
                                <NavLink className={liClass} to={RoutePaths.game} >
                                    <RxTransparencyGrid />
                                </NavLink>
                            </li>
                            <li title={i18n.analizeTitle}>
                                <NavLink  className="navlink" to={RoutePaths.analysis}>
                                    <PiMagnifyingGlassPlusBold />
                                </NavLink>
                            </li>
                            <li title={i18n.feedbackTitle}>
                                <NavLink className={liClass} to={RoutePaths.feedback} >
                                    <RiFeedbackLine />
                                </NavLink>
                            </li>
                            <li title={i18n.profileTitle}>
                                <NavLink className={liClass} to={RoutePaths.profile} >
                                    <MdOutlinePerson4 />
                                </NavLink>
                            </li>
                            <li title={i18n.settingsTitle}>
                                <NavLink className={liClass} to={RoutePaths.settings} >
                                    <MdOutlineSettings />
                                </NavLink>
                            </li>
                        </>
                        : null
                }
                {!isAuth || !externalLogBar
                    ? <AuthMenu /> 
                    : null
                }
            </ul>
            {isAuth && externalLogBar ? <LoginBar /> : null}
        </nav>
        </div>
    )
}

export default connector(Navbar)
