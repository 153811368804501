// import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GrSearchAdvanced } from "react-icons/gr";
import { LuX } from 'react-icons/lu'
import { TbPuzzle } from 'react-icons/tb'
import { CgMenuGridR } from 'react-icons/cg'
import {
    requestRevenge, createChallenge, setGameState, InitialGameState
} from '../../store/gameStateSlice'
import { AppType, GameResult, IPuzzle, ITopState } from '../../models/models'
import { EngineLevels, TopState } from '../../constants/gameConstants'
import { Ads } from '../Ads/Ads'
import { 
    selectGameOptionsWithProps, selectGameStateWithProps, selectUserWithProps, selectWithProps 
} from '../../store/rootState&Reducer'
import { I18n } from '../../assets/i18n'
import { choosePuzzle, setAppType, showAds } from '../../store/topStateSlice'
import { Logo } from '../Logo/LogoIcon'
import { FadeIn } from '../FadeIn/FadeIn'

import './event-end-popup.scss'

export const EventEndPopup = () => {
    const navi = useNavigate()
    const {
        type, puzzles
    } = useSelector(selectWithProps(['type', 'puzzles'], TopState))
    const {
        result, playerColor
    } = useSelector(selectGameStateWithProps(['result', 'playerColor']))
    const {
        rivalType, rivalLevel, timing: {adds, gameTimeLimit}
    } = useSelector(selectGameOptionsWithProps(['rivalType', 'rivalLevel', 'timing']))
    const {puzzleResolved, selectedPuzzle} = useSelector(
        selectWithProps(['puzzleResolved', 'selectedPuzzle'], TopState)
    )  as ITopState
    const {name, language} = useSelector(
        selectUserWithProps(['name', 'language', 'exp'])
    )
    const i18n = I18n[language.slice(0, 2)] || I18n.en
    
    const vsEngine = rivalType === 'engine'
    const dispatch = useDispatch()
    
    const playerWin = result === GameResult[playerColor] || puzzleResolved?.resolved

    const handleClose = () => {
        dispatch(showAds(false))
    }
    
    const message = result 
        ? (result === GameResult.draw
            ? i18n.drawMsg
            : (result === GameResult.canceled
                ? i18n.gameCanceledMsg
                : playerWin 
                    ? `${i18n.gameCongrats}` 
                    : `${i18n.loseMsg}`
            )) 
        : (playerWin 
            ? `${i18n.puzzleCongrats[Math.floor(Math.random()*4)]} ${name}!`
            : i18n.puzzleSorry
        )
    
    const handleRematch = () => {
        dispatch(showAds(false))
        dispatch(requestRevenge())
    }

    const handleAnalysis = () => {
        dispatch(showAds(false))
        dispatch(setAppType(AppType.analysis))
        navi('../../analysis')
    }

    const handleBackToOptions = () => {
        dispatch(showAds(false))
        if (type === AppType.puzzles) {
            dispatch(choosePuzzle(''))
        } else {
            dispatch(setGameState({...InitialGameState}))
        }
    }

    const newEvent = () => {
        dispatch(showAds(false))
        if (!result) {
            const next = (puzzles.find((p: IPuzzle) => p._id !== selectedPuzzle)) 
            if (next) {
                dispatch(choosePuzzle(next._id))
            }
        } else {
            dispatch(createChallenge(true))
        }
    }

    // const btn1_name = result
    //     ? i18n.gameOptions
    //     : i18n.backToPuzzMenu

    const btn3_name = result
        ? (vsEngine 
            ? `New vs ${EngineLevels[rivalLevel]}`
            : `New ${gameTimeLimit} / ${adds}`)
        : <TbPuzzle />

    // console.log('event end popup', message, result, puzzleResolved)
    const clName = "event-end-popup noselect" + (puzzleResolved?.animated ? ' puzzle'  : '')
    return  (
       
        <section className={clName}>
            <FadeIn>
            <header className={`popup-header ${playerWin ? 'win-bg' : 'lose-bg'}`}>
                <h4 className="popup-title">{message}</h4> 
                <button
                    title={i18n.closeTitle} 
                    type="button" 
                    className="popup-button close"
                >
                    <LuX onClick={handleClose} />
                </button>
            </header>
            <div className="popup-body">
                <Ads />
            </div>
            <div className={`popup-footer ${playerWin ? 'win-bg' : 'lose-bg'}`}>
                {vsEngine || puzzleResolved
                    ? null
                    : <button 
                        title={playerWin ? i18n.rematchWTitle : i18n.rematchLTitle}
                        className="popup-button rematch" 
                        type="button"
                        onClick={handleRematch}
                    >
                        {playerWin ? i18n.rematchWTitle : i18n.rematchLTitle}
                    </button>
                }
                <button 
                    title={result ? i18n.newGameMenuTitle : i18n.backToPuzzMenuTitle}
                    type="button" 
                    className="popup-button logo" 
                    onClick={handleBackToOptions}
                >
                    {result ? <Logo size={18}/> : <CgMenuGridR />}
                </button>
                {(type === AppType.puzzles && puzzles.length) || AppType.game
                    ? <button 
                        className="popup-button new-rival" 
                        type="button"
                        onClick={newEvent}
                    >
                        {btn3_name}
                    </button>
                    : null
                }
                <button 
                    title={i18n.analizeTitle}
                    type="button" 
                    className="popup-button analysis" 
                    onClick={handleAnalysis}
                >
                    <GrSearchAdvanced />
                </button>
            </div>
            </FadeIn>
        </section>
    ) 
}
 
export default EventEndPopup
