import {Observable} from "./observable";

import { env } from '../env'
import { isDev } from "../local-engine/gameplay-helper-fn";

export class WSService {
    ws = {readyState: 3} as WebSocket
    onlineBot = ''
    messageFromBot = [] as  {message: '', payload: null}[]
    connecting = 0
    connected = 0
    attempts = 0
    token = false
    message = null as any
    timeouts = null as unknown as NodeJS.Timeout
    $message = new Observable(this.message)
    $ws = new Observable(this.ws)
    $onlineBot = new Observable(this.onlineBot)

    constructor() {
        if (this.token) {
            this.connect()
        }
    }

    setOnlineBot = (bot: string) => {
        isDev() && console.log('bot', bot)
        this.onlineBot = bot
        this.$onlineBot.emit(this.onlineBot)
    } 

    // messToBot = (mess: any) => {
    //     console.log('mess to bot', mess)
    //     this.messageToBot = mess
    //     this.$messageToBot.emit(this.messageToBot)
    // }

    // botMessage = (mess: any) => {
    //     if (!this.ws) {
    //         this.messageFromBot.push(mess)
    //     } else {
    //         this.ws.send(JSON.stringify(mess))
    //     }
    // }

    emitMessage = (mess: any) => {
        this.message = mess
        this.$message.emit(this.message)
    }
  
    connect = () => {
        // console.log(this.ws.readyState, this.connecting, this.connected)
        if (this.connecting || this.connected) return
        this.connecting = Date.now()
        this.attempts = (this.attempts + 1) % 10
        this.ws = new WebSocket(env.wsUrl)
        this.ws.onopen = () => {
            isDev() && console.log('connected', this.ws.readyState)
            this.connecting = 0
            this.connected = 1
            this.$ws.emit(this.ws)      
        };
        this.ws.onmessage = ((event: any) => {
            this.message = JSON.parse(event.data)
            isDev() && console.log('new message', event, this.onlineBot, this.message)
            if (this.message.message === 'game over' || this.message.message === 'game canceled') {
                this.onlineBot = ''
                this.$onlineBot.emit(this.onlineBot)
            }
            this.$message.emit(this.message)
        })       
        this.ws.onclose = () => {
            isDev() && console.error('WebSocket disconnected', this.ws?.readyState, this.token);
            this.connecting = 0
            this.connected = 0
            this.$ws.emit(null as unknown as WebSocket)
            if (this.token) {
                this.reconnect()
            }
            
        };
    }

    setToken = (token = false) => {
        this.token = token
        if (token && !this.connected && !this.connecting) {
            this.reconnect()
        }
    }

    reconnect = () => {
        if (!this.attempts && !this.connecting) {
            this.connect()
        } else {            
            this.timeouts = setTimeout(this.connect, this.attempts * this.attempts * 1000)
        }
    }
}

export const wsService = new WSService()
export default WSService
