/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react"

import {
    copyObj, delayer, fullPosition, getEngLevel, getRandomDebuteMove, isDev, positionSummary
} from "./local-engine/gameplay-helper-fn"
import PosTree from "./local-engine/moves-tree"
// import { moveResolver as moveR } from "./local-engine/move-resolver"
import { PieceColor as PC } from "./models/models"
import { createStartPosition } from "./local-engine/board-helper-fn"
import { wsService } from "./common/ws-service"
import MoveR from './local-engine/move-resolver'
import Evaluator from './local-engine/evaluator'
import storage from "./common/storage"

const moveR = new MoveR({})
const evaluator = new Evaluator(moveR)

const  posTree = new PosTree({
    engineLevel: 1, turn: PC.white, game: true, position: {}, evaluator
})

const botState = {
    gameKey: '',
    readyState: 3,
    ping: 0,
    botId: '',
    engineColor: '',
    moves: '',
    moveLs: [] as any[],
    msQueue: [] as any[],
    // attempts: 0
}

const PingInterval = 29000 * 60 

const Bot = () => {
    const idSend = useRef(false)
    const {token, userId} = storage.getUser()
    const gameKeyR = useRef('')
    const botId = useRef(userId)
    
    const intervals = useRef({
        interval: null as unknown as NodeJS.Timeout,
        timeout: null as unknown as NodeJS.Timeout,
    } as any)
    const [ws, setWs] = useState(wsService.ws)

    const setState = (props: any) => {
        for (const key in props) {
            (botState as any)[key] = props[key]
            const el = document.getElementById(key)
            if (!el) continue
            el.innerText = props[key]
        }
    }
    const messHandler = (props: any) => {
        const {message, payload} = props
        // console.log('mes to bot', props, botState)
        switch (message) {
            case 'id': {
                botId.current = payload
                setState({botId: payload})
                break
            }
            case 'bot added': {
                // const nState = {...getState(), readyState: ws?.readyState}
                setState({readyState: ws?.readyState})
                pingPong()
                break
            }
            case 'game move': {
                if (!Object.keys(payload.position).length) {
                    isDev() && console.error('invalid props', props)
                }
                payload.position = fullPosition(payload.position)
                const res = resolveMove(payload)
                if (!res.move) { return } 
                const val = posTree.getRoot().deepValue.value
                const mLen = botState.moves.length
                const moves = botState.moves + (mLen ? "_" : '') + res.move
                setState({moves})
                if ((botState.engineColor === PC.white && val < -4)
                    || (botState.engineColor === PC.black && val > 4)) {
                    if (wsService.ws.send) {
                        const gameKey = gameKeyR.current
                        wsService.ws.send(JSON.stringify({
                            message: 'game surrender', 
                            payload: {gameKey}
                        }))
                        return
                    } 
                }
                const mess = {
                    message: 'game move', 
                    payload: {move: res.move, gameKey: gameKeyR.current}
                }
                if (wsService.ws.send) {
                    setTimeout(() => wsService.ws.send(JSON.stringify(mess)), delayer())
                } else {
                    setState({msQueue: botState.msQueue.concat(mess)})
                }
                setState({moves})
                break
            }
            case 'new game': {
                clearInterval(intervals.current.interval)
                const {GV, turn, game = true, white, gameKey} = payload
                const size = +payload.bSize
                const position = payload.position || createStartPosition(size, GV) 
                const st = Date.now()
                if (!size || !GV || !white || !gameKey) {
                    console.error('invalid props to set up engine worker', payload)
                    return
                }
                clearInterval(intervals.current.interval)
                clearTimeout(intervals.current.timeout)
                gameKeyR.current = gameKey
                const engineColor = white.userId === botId.current ? PC.white : PC.black
                const engineLevel = getEngLevel(white.rating)
                const EngineProps = {engineLevel, position, turn, game, size, GV}
                posTree.setPropsAndCreateTree(EngineProps)
                isDev() && console.log('engine set up successfully', copyObj(posTree), Date.now() - st)
                const messKey = engineColor === PC.white ? 'wReady' : 'bReady'
                const mess = {
                    message: 'game ready to play',
                    payload: {[messKey]: 1, gameKey}
                }
                if (wsService.ws.send) {
                    wsService.ws.send(JSON.stringify(mess))
                } else {
                    setState({msQueue: [mess]})
                }
                setState({gameKey, engineColor})
                isDev() && console.log('new game state', botState)
                break
            }
            case 'draw offered': {
                const currentValue = posTree.getRoot().deepValue.value
                const piecesNumber = Object.keys(posTree.currentPos).length
                const accept = piecesNumber < 3 && Math.abs(currentValue) < 1
                const mess = {
                    message: accept ? 'game draw accepted' : 'game draw declined',
                }
                if (!wsService.ws.send) {
                    setState({msQueue: botState.msQueue.concat(mess)})
                    return 
                }
                wsService.ws.send(JSON.stringify(mess))
                break
            }
            case 'game over': {
                gameKeyR.current = ''
                setState({gameKey: '', moves: ''})
                wsService.ws.send(JSON.stringify({
                    message: 'bot ready',
                }))
                pingPong()
                break
            }
            case 'game canceled': {
                gameKeyR.current = ''
                setState({gameKey: '', moves: ''})
                wsService.ws.send(JSON.stringify({
                    message: 'bot ready',
                }))
                pingPong()
                break
            }
            case 'game started': {
                if (botState.engineColor === PC.white) {
                    const move = resolveFirstMove()
                    const mess = {
                        message: 'game move', 
                        payload: {
                            ...move, 
                            gameKey: gameKeyR.current,
                        }
                    }
                    if (!wsService.ws.send) {
                        setState({msQueue: [mess]})
                    }
                    setState({moves: move.move})
                    wsService.ws.send && wsService.ws.send(JSON.stringify(mess))
                }
                break
            }
            default: {
                break
            }
        }
    }
    
    useEffect(() => {
        wsService.setToken(true)
        const unsubWs = wsService.$ws.subscribe(setWs)
        const unsubWsMess = wsService.$message.subscribe(messHandler)
        return () => {
            clearInterval(intervals.current.interval)
            clearTimeout(intervals.current.timeout)
            unsubWs()
            unsubWsMess()
        }
    }, [])
    
    useEffect(() => {
        isDev() && console.log('ws', ws?.readyState, idSend.current)
        if (!ws || ws?.readyState !== 1 || !ws.send) {
            idSend.current = false
            setState({readyState: 3})
            return
        }
        if (!idSend.current) {
            idSend.current = true
            const mess = JSON.stringify({
                message: 'new bot',
                payload: {
                    botId: botState.botId,
                    gameKey: gameKeyR.current,
                    token
                }
            })
            ws.send(mess)
        }
        if (botState.msQueue.length) {
            for (const mess of botState.msQueue) {
                if (mess.message !== 'new bot' && mess.message !== 'ping') {
                    ws.send(JSON.stringify(mess))
                }
            }
            setState({msQueue: []})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws])

    const ping = (mes: any = {message: 'ping'}) => {
        if (!ws || ws?.readyState > 1) return
        setState({ping: botState.ping + 1})
        ws.send(JSON.stringify(mes))
    }

    const pingPong = () => {
        const message = {message: 'ping'}
        if (intervals.current.interval) {
            clearInterval(intervals.current.interval)
        }
        intervals.current.interval = setInterval(ping, PingInterval, message)
    }

    const resolveMove = (data: any) => {
        const move = posTree.resolvePlayerMove(data)
        if (Object.keys(data.position).length 
            && positionSummary(posTree.currentPos) !== positionSummary(data.position)) {
            console.error('invalid position', posTree.currentPos, data.position)
            throw new Error('invalid move')
        }
        if (!move) {
            return {move: ''}
        }
        posTree.updateTreeAfterMove(move)
        isDev() && console.warn('engine move', move, copyObj(posTree))
        return {move}
    }
    
    const resolveFirstMove = () => {
        const move = getRandomDebuteMove(posTree.getOrderedMoves())
        posTree.updateTreeAfterMove(move)
        return {move}
    }

    return (
        <div className="bot-page">
            <div className="bot-container">
                <div>gameKey: <span id='gameKey'>{botState.gameKey}</span></div> 
                <div>messQueue: <span id='msQueue'>{JSON.stringify(botState.msQueue)}</span></div>
                <div>botId: <span id='botId'>{botState.botId}</span></div> 
                <div>EngineColor: <span id='engineColor'>{botState.engineColor}</span></div> 
                <div>pings: <span id='ping'>{botState.ping}</span></div> 
                <div style={{"maxWidth": "600px"}} >moves: <div id='moves'>{botState.moves}</div></div>
            </div>
        </div>
    )
}

export default Bot
