import { useState } from "react"
// import { MdOutlineArrowDropUp, MdOutlineArrowDropDown } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import './input.scss'
import useCloseEffect from "../../hooks/useCloseEffect";

export interface IMenuProps {
    menu: {value: string, name: string}[], 
    handler: Function, 
    actual: string, 
    classN: string 
    titles?: string[]
    select?: boolean
    selectName?: string
    selectId?: string
}

export const MenuComponent = (props: IMenuProps) => {
    const {actual, handler, menu, classN, titles} = props
    const handleSelect = (e: any) => {
        e.preventDefault()
        const target = e.target as HTMLButtonElement
        target.blur()
        handler(target.value)
    }
    const menuItemClass =  (value: string) => 
        `menu-item ${actual.toLowerCase() === value.toLowerCase() ? ' actual' : ''}`
    
    if (props.select) {
        return <CustomSelect {...props} />
    }
    
    return (
        <div className={classN}>
            {
                menu.map((i, j) => {
                    return (
                        <button
                            key={i.value}
                            onClick={handleSelect}
                            title={titles ? titles[j] : ''}
                            className={menuItemClass(i.value)}
                            type="button"
                            value={i.value.toLowerCase()}
                        >
                            {i.name}
                        </button>   
                    )
                })
            }
        </div>
    )
}


export const CustomSelect = (props: IMenuProps) => {
    const [open, setOpen] = useState(false)
    const addListeners = useCloseEffect(`${props.selectId}`, setOpen, true)
    const handleSelect = (e: any) => {
        const target = e.target as HTMLButtonElement
        target.blur()
        props.handler(target.value)
    }
    const handleOpen = () => {
        if (classN.includes('disabled')) return
        setOpen(!open)
        addListeners(true)
    }
    const {classN, actual, menu, selectId, titles} = props
    const title = titles ? titles[0] : ''
    return (
        <div 
            className={"select-wrapper " + classN} 
            title={title}
            id={props.selectId}
        >
            <button 
                className={"select-btn dropdown-btn actual " + selectId}
                type="button"
                onClick={handleOpen}
                // onTouchEnd={handleOpen}
            >
                {menu.filter(m => m.value === actual)[0].name}
                {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </button>
            <div className={"select-dropdown" + (open ? '' : ' hidden')}>
            {
                menu.map(i => (
                    <button
                        type="button"
                        className={"select-btn" + (actual.toLowerCase() === i.value.toLowerCase() ? ' actual' : '')}
                        key={i.name} 
                        value={i.value}
                        onClick={handleSelect}
                        onTouchStart={handleSelect}
                    >
                        {i.name}
                    </button>))
            }
            </div>
        </div>
    )
}
