import {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'

import WaitingRival from '../../UI-components/Spinners/WatingRival'
import { IRootState } from '../../models/models'
import { RoutePaths, TopState } from '../../constants/gameConstants'
import { startEngine } from '../../store/topStateSlice'
import { selectMode, selectTheme } from '../../store/userSlice'
import { selectGameStateWithProps, selectWithProps } from '../../store/rootState&Reducer'
import { FadeIn } from '../../UI-components/FadeIn/FadeIn'

import './game-page.scss'

const GamePage = () => {
    const theme = useSelector(selectTheme)
    const mode = useSelector(selectMode)
    // const game = useSelector((state: IRootState) => state.topState.type === AppType.game)
    const navigate = useNavigate()
    const location = useLocation()
    const rivalType = useSelector((state: IRootState) => state.gameSettings.rivalType)
    const {gameKey, result, waitingForRival} = useSelector(
        selectGameStateWithProps(['gameKey', 'result', 'waitingForRival']))
    const {localEngine, type} = useSelector(selectWithProps(['localEngine', 'type'], TopState))
    const dispatch = useDispatch()
    useEffect(() => {
        // if (!game) dispatch(setAppType(AppType.game))
        if (!gameKey || result) {
            navigate(RoutePaths.presets)
            localEngine && dispatch(startEngine(false))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (gameKey) {
            if (rivalType === 'engine') {
                navigate(RoutePaths.gameOfline.replace(':gameId', gameKey))
            } else {
                navigate(RoutePaths.gameOnline.replace(':gameId', gameKey))
            }
        } else if (!waitingForRival && location.pathname !== '/game/presets') {
            navigate(RoutePaths.presets)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waitingForRival, location.pathname, gameKey]) 

    return (
        <FadeIn>
        <div className={`page-wrapper noselect mode-${mode} theme-${theme} type-${type}`}>
            {waitingForRival ? <WaitingRival /> : null}
            <Outlet />
        </div>
        </FadeIn>
    )
} 

export default GamePage
