/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useEffect, useState, memo } from 'react'
import { useSelector } from 'react-redux'
import {  MdOutlinePauseCircle, MdOutlinePlayCircle } from "react-icons/md";

import { BaseBoardSize } from '../../constants/gameConstants'
import Spinner from '../../UI-components/Spinners/Spinner'
import BGBoard from '../../UI-components/BgBoard/Bg-board'
import { I18n } from '../../assets/i18n'
import { AnimatedBoard } from '../../UI-components/Board/AnimatedBoard'
import { selectWindowSize } from '../../store/topStateSlice';
import { selectUserWithProps } from '../../store/rootState&Reducer';

import './about.scss'
import { FadeIn } from '../../UI-components/FadeIn/FadeIn';
import { Demos } from '../../constants/debutes-demos';
import { getExampleCellSize } from '../../local-engine/gameplay-helper-fn';

export const ExampleGame = () => {
    const [pause, setPause] = useState(false)
    const winSize = useSelector(selectWindowSize)
    const [cS, setCs] = useState(getExampleCellSize(winSize, BaseBoardSize))
    useEffect(() => {
        if (winSize.width < 560) {
            cS !== 35 && setCs(35)
        } else if (winSize.width >= 560 && winSize.width < 960) {
            cS !== 30 && setCs(30)
        } else if (winSize.width >= 960 && winSize.width < 1200) {
            cS !== 40  && setCs(40)
        } else if (winSize.width >= 1200) {
            cS !== 45 && setCs(45)
        }           
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [winSize])
    return (
        <button
            type='button' 
            className="game-example-wrapper"
            onClick={() => setPause(!pause)}
        >
            <AnimatedBoard 
                stop={pause} 
                cS={cS}
                num={3}
            />
            <div className='example-ctrl-btn'>
                {!pause ? <MdOutlinePauseCircle /> : <MdOutlinePlayCircle />}
            </div>
        </button>
    )
}

export const About = memo(() => {
    const {
        viewMode: mode, theme, language
    } = useSelector(selectUserWithProps(['viewMode', 'theme', 'language']))
    const i18n = I18n[language.slice(0, 2)] || I18n.en  
        
    return (
        <Suspense fallback={<Spinner />}>
            <FadeIn>
                <div className='page-wrapper noselect'>
                    <BGBoard />
                    <div className={`about-page mode-${mode} theme-${theme}`}>
                        <h1>{i18n.about_title || i18n.en.about_title}</h1>
                        <div className='description-wrapper'>
                            <ExampleGame />
                            <div className={`game-description`}>{i18n.about_start}</div>
                        </div>
                    </div>
                </div>
            </FadeIn>
        </Suspense>
    )
})

export default About
