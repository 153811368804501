import { useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, Suspense } from 'react'

import { PuzzlesMenu } from './PuzzlesMenu'
import { ITopState } from '../../models/models'
import { 
    selectUserWithProps, selectWithProps 
} from '../../store/rootState&Reducer'
// import { choosePuzzle } from '../../store/topStateSlice'
import Spinner from '../../UI-components/Spinners/Spinner'
import { RoutePaths, TopState } from '../../constants/gameConstants'
import { isDev } from '../../local-engine/gameplay-helper-fn'

import './puzzles.scss'
import { FadeIn } from '../../UI-components/FadeIn/FadeIn'


export const Puzzles = () => {
    const { 
        selectedPuzzle: puzzleId, puzzleResolved
    } = useSelector(selectWithProps(['selectedPuzzle', 'puzzleResolved'], TopState)) as ITopState
    const { resolvedPuzzles } = useSelector(selectUserWithProps(['resolvedPuzzles']))
    const {puzzles} = useSelector(selectWithProps(['puzzles'], TopState))
    const location = useLocation()
    // const isGame = useSelector(selectIsGame)
    const {
        viewMode: mode, theme
    } = useSelector(selectUserWithProps(['viewMode', 'theme', 'exp']))
    
    const navi = useNavigate()

    useEffect(() => {
        const resolved = puzzleId && puzzleResolved
        isDev() && console.log(
            location.pathname, 
            puzzleId,
            resolvedPuzzles,
            puzzles,
            resolved,
        )      
        if ((!puzzleId && location.pathname === '/puzzles')
            || (puzzleId && location.pathname === `/puzzles/${puzzleId}`)
        ) return
        if (!puzzleId && location.pathname !== '/puzzles') {
            const prepath = location.pathname.split('/').slice(2).map(n => '../').join('')
            return navi(`${prepath}${RoutePaths.puzzles}`)
        } else if (puzzleId && location.pathname !== `/puzzles/${puzzleId}`){
            const prepath = location.pathname.split('/').slice(3).map(n => '../').join('')
            navi(`${prepath}${puzzleId}`)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, puzzleId, resolvedPuzzles])

    return (
        <Suspense fallback={<Spinner />}>
            <FadeIn>
            <div className={`page-wrapper noselect mode-${mode} theme-${theme}`}>
                {
                    puzzleId
                    ? <Outlet />
                    : <PuzzlesMenu />
                }
            </div>
            </FadeIn>
        </Suspense>
    )
}

export default Puzzles


