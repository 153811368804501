
import { useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Axios } from "../../common/axios"
import { I18n } from "../../assets/i18n"
import validate, { valArgs } from "../../common/helpers/inputValidation"

import { useMessageShow } from "../../hooks/useShowMessage"
import { IRef } from "../../models/models"
import { isDev } from "../../local-engine/gameplay-helper-fn"
import { Input, InputPassword } from "../../UI-components/Input/Input"
import { selectUserWithoutProps } from "../../store/rootState&Reducer"
import { FadeIn } from "../../UI-components/FadeIn/FadeIn"



export const SignupPage: React.FC = () => {
    const user = useSelector(selectUserWithoutProps(['token', 'userId']))
    const [form, setForm] = useState({name: '', password: '', email: ''})
    const submitted = useRef(false)
    const formRef: IRef<any> = useRef()
    const navigate = useNavigate()
    const {showMessage, fadeMessage} = useMessageShow()
    const {viewMode: mode, theme, language} = user
    const i18n = I18n[language.slice(0, 2)] || I18n.en

    const handleCheckName = async () => {
        const body = JSON.stringify({name: form.name})
        const url = `/api/auth/checkname`
        try {
            await Axios.post(url, body)
        } catch(e: any) {
            showMessage({msg: e.response?.data?.message || e.message, ok: false})
            const form = formRef.current as HTMLFormElement
            form.querySelector('#name')?.parentElement?.classList.add('error')
            isDev() && console.error(e)
        }
    }

    const handleRegistration = async () => {
           
        const body = JSON.stringify({...user, ...form})
        const url = `/api/auth/register`
        try {
            const data: any = await Axios.post(url, body)
            showMessage({msg: data.message || '', ok: data.status < 400})
           
            if (data.status < 300) {
                navigate(`..`)
            }
        } catch(e: any) {
            showMessage({msg: e.response?.data.message || e.message, ok: false})
            console.error(e)
        } finally {
            submitted.current = false
        }
    }

    const handleBlur = (e: React.FocusEvent) => {
        e.preventDefault()
        const target = e.target as HTMLInputElement
        if (target.type === "submit") {
            return
        }
        const id = target.id as valArgs
        const error = validate(id)(target.value)
        if (error.message) {
            showMessage({msg: error.message, ok: false})
            target.parentElement?.classList.add('error')
        }
        if (target.id === 'name' && !error.message) {
            handleCheckName()
        }   
    }

    const handleChanges = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault()
        const target = e.target as HTMLInputElement 
        fadeMessage()
        if (!target.parentElement) return
        if (target.value) {
            target.parentElement.classList.add('dirty')
        } else {
            target.parentElement.classList.remove('dirty')
        }
        const id = target.id as valArgs
        if (target.parentElement.classList.contains('error')) {
            const error = validate(id)(target.value)
            if (!error.message) {
                target.parentElement.classList.remove('error')
            }
        }
        setForm(form => ({...form, [target.name]: target.value}))
    }
    
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        isDev() && console.log(
            form,  
            (e.target as HTMLFormElement).querySelector('.error')
        )
        if (submitted.current) return
        const target = e.target as HTMLFormElement
        const errors = target.querySelector('.error')
        if (errors || !form.password || !form.email || !form.name) {
            return
        }
        submitted.current = true
        handleRegistration()
    }
    
    const formMessage = i18n.regMess
    return (
        <FadeIn>
        <div className={"auth-page mode-" + mode + ' theme-' + theme + " noselect"} >
            <div className="form-wrapper " >
                <div className="form-title-wrapper">
                    <h1>{i18n.about_title}</h1>
                    <p><span>{formMessage}</span></p>
                </div>
                
                <form onBlur={handleBlur} onSubmit={handleSubmit} ref={formRef} autoComplete="off">
                    <Input 
                        label={i18n.labelNickname}
                        id="name"
                        name="name"
                        type="text"
                        value={form.name}
                        handleChanges={handleChanges}
                    />
                     <Input 
                        label={"E-mail"}
                        id="email"
                        name="email"
                        type="email"
                        value={form.email}
                        handleChanges={handleChanges}
                    />  
                    <InputPassword 
                        label={i18n.labelPassword}
                        id="password"
                        name="password"
                        value={form.password}
                        handleChanges={handleChanges}
                    />                    
                    <input type="submit" value={i18n.submitButton} />
                </form>
                <div>
                        <button type="button" 
                            onClick={() => navigate('..')} 
                            className="to-sign-in-btn"
                        >
                            {i18n.modeButtonReg}
                        </button>
                    </div>
            </div>
        </div>
        </FadeIn>
    )
}

export default SignupPage
