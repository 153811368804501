import { useEffect, useState } from 'react'

import  {IBoardPieces, IPuzzle} from '../../models/models'

import { useSelector } from 'react-redux';
import { selectUserWithProps } from '../../store/rootState&Reducer';
import {TowerComponent} from '../Tower/GamePiece'

import './board.scss';
import { BoardComponent } from './Board'
import { 
    createCellsMap, updateCellsMap, updatePiecesPosition 
} from '../../local-engine/board-helper-fn';


export const PuzzleBoard = (props: {puzzle: IPuzzle, cellBase: number}) => {
    const {bSize, position: pos} = props.puzzle
    const cellSize = bSize > 8 && props.cellBase > 20 ? props.cellBase - 5 : props.cellBase
    const [cellsMap, setCellsMap] = useState(createCellsMap(bSize, cellSize))
    const [position, setPosition] = useState(
        updatePiecesPosition(pos as IBoardPieces, cellsMap, cellSize, bSize)
    )
    const {
        pieceColors, theme, viewMode: mode, towerView: view
    } = useSelector(selectUserWithProps(['pieceColors', 'theme', 'viewMode', 'towerView']))
    const WrapperClass = `board__wrapper mode-${mode} theme-${theme} demo-board`
    const Towers = []

    useEffect(() => {
        const nCellsMap = updateCellsMap({cellsMap, cellSize: cellSize, boardSize: bSize})
        setCellsMap(nCellsMap)
        setPosition(updatePiecesPosition(position, nCellsMap, cellSize))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cellSize])

    for (const key in position as IBoardPieces) {
        Towers.push(
            <TowerComponent 
                key={key} 
                posKey={key}
                gamePiece={(position as IBoardPieces)[key]} 
                view={view} 
                pieceColors={pieceColors}
                cellSize={cellSize}
            />
        )
    }

    return (
        <>
            <section className={WrapperClass}>
                {Towers}
                <BoardComponent bs={bSize} bw={cellSize*bSize} />
            </section>
        </>
    )
}
