import { createSlice } from "@reduxjs/toolkit";
import { ITopState, AppType, IRootState } from "../models/models";
import { DefMessage } from "../hooks/useShowMessage";

export const AppInitialState: ITopState = {
    windowSize: {width: window?.innerWidth || 0, height: window?.innerHeight || 0},
    type: AppType.puzzles,
    wsConnectionState: 0,
    wsMessage: null,
    backMessage: DefMessage,
    localEngine: false,
    puzzles: [],
    selectedPuzzle: '',
    solution: [],
    eSolution: [],
    puzzleResolved: null,
    appModeUser: true,
    isDevice: false,
    ads: false,
    onboardingStep: 3
}

const appSlice = createSlice({
    name: 'app',
    initialState: AppInitialState,
    reducers: {
        getNewPuzzles() {},
        updateAppState(state, action) {return {...state, ...action.payload}},
        showAds(state, action) {state.ads = action.payload},
        setDevice(state, action) {state.isDevice = action.payload},
        setAppType(state, action) {state.type = action.payload},
        setAppState(state, action) { return {...state, ...action.payload} },
        setSolution(state, action) {
            state.solution = action.payload;
            state.eSolution = action.payload;
        },
        // analizePuzzle() {},
        setESolution(state, action) {state.eSolution = action.payload},
        choosePuzzle(state,action) {state.selectedPuzzle = action.payload},
        getPuzzles() {},
        setOnboardingStep(state, action) {state.onboardingStep = action.payload},
        setAppMode(state, action) {state.appModeUser = action.payload},
        savePuzzle(state, action) {}, 
        setPuzzles(state, action){state.puzzles = action.payload},
        setBackMessage(state, action) {state.backMessage = action.payload},
        setWindowSize(state, action) { return {...state, windowSize: action.payload} },
        makePMove() {},
        setConnectionState(state, action) {state.wsConnectionState = action.payload},
        setPuzzleStatus(state, action) {state.puzzleResolved = action.payload},
        startEngine(state, action) {state.localEngine = action.payload},
        sendWsMessage(state, action) {state.wsMessage = action.payload},
        skipPuzzle(state) {},
    }
})

export const {actions} = appSlice
export const {
    setDevice,
    getNewPuzzles,
    setAppType,
    setESolution,
    setWindowSize, 
    sendWsMessage, 
    setBackMessage, 
    startEngine,
    setAppState,
    getPuzzles,
    setPuzzles,
    choosePuzzle,
    setSolution,
    makePMove,
    savePuzzle,
    setPuzzleStatus,
    setConnectionState,
    skipPuzzle,
    setAppMode,
    showAds,
    setOnboardingStep,
    updateAppState,
    // analizePuzzle
} = actions

export default appSlice.reducer

export const selectWindowSize = (state: IRootState) => state.topState.windowSize
export const selectAppType = (state: IRootState) => state.topState.type
