export type Mode = 'development' | 'stage' | 'production' | 'productionAws'
const mode: Mode = process.env.NODE_ENV as Mode

mode !== 'production' &&  console.log(mode)

export const Config: {[key: string]: any} = { 
    development : {
        baseURL: 'http://localhost:5050',
        wsUrl: 'ws://127.0.0.1:5050/ws',
    },
    stage : {
        baseURL: 'http://localhost:80',
        wsUrl: 'ws://localhost:80/ws',
    },
    production : {
        baseURL: 'https://tcheckers.com',
        wsUrl: 'wss://tcheckers.com/ws',
    },
    productionAws: {
        baseURL: 'http://3.248.250.48:80',
        wsUrl: 'ws://3.248.250.48:80/ws',
    }
}

export const env = Config[mode]
