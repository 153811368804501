
import { createSlice } from "@reduxjs/toolkit";
import { IAnalysisState, PieceColor } from "../models/models";

export const InitialAnalysisState: IAnalysisState = {
    evalDepth: 5,
    gameMoves: [],
    bestMoveLines: [] as { line: string[]; value: number }[],
    settingPosition: true,
    removingPieces: false,
    settingKing: false,
    unused: {},
}

const analyisSlice = createSlice({
    name: 'analysis',
    initialState: InitialAnalysisState,
    reducers: {
        toggleRemovingPieces(state, action) {state.removingPieces = action.payload},
        setKing(state, action) {state.settingKing = action.payload},
        setEvalDepth(state, action) {state.evalDepth = action.payload},
        setBestMoveLines(state, action) {state.bestMoveLines = action.payload},
        setGameMoves(state, action) {state.gameMoves = action.payload},
        setPosition(state, action) {state.settingPosition = action.payload},
        updateAnalysisState(state, action) {return {...state, ...action.payload}},
        setUnused(state,action) {state.unused = action.payload},
        selectGame(state, action) {state = InitialAnalysisState}
    }
})

export const {
    // analyzeGame, 
    setEvalDepth, 
    setBestMoveLines,
    setGameMoves, 
    updateAnalysisState,
    setPosition,
    setKing,
    toggleRemovingPieces,
    setUnused,
    // setStartTurn,
    selectGame
} = analyisSlice.actions

export default analyisSlice.reducer
