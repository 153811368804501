import { useEffect } from 'react'
import {connect, ConnectedProps, useDispatch, useSelector} from 'react-redux'
import { AiOutlineCopyright } from "react-icons/ai";
// import git from '../../assets/images/icons8-github.svg'

// import { IRootState } from '../store/rootState&Reducer'
import { choosePuzzle, selectAppType, setAppType, setWindowSize } from '../../store/topStateSlice'
import WebSocketClient from '../../Web-sockets/Ws'
import { AppType, GameResult, IRootState, ITopState } from '../../models/models'
import { updateBoardState } from '../../store/boardSlice'
import { close } from '../../store/userSlice'
import { calcPiecePosition, updateCellsMap } from '../../local-engine/board-helper-fn'
import { ConnectionIndicator } from '../ConnectionIndicator/ConnectionIndicator'
import { calcCellSize, copyObj, isDev, oppositeColor } from '../../local-engine/gameplay-helper-fn'

import './footer.scss'
import { updatePosition } from '../../store/gameSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { selectIsGame, selectWithProps } from '../../store/rootState&Reducer'
import { TopState } from '../../constants/gameConstants'
import { endGame } from '../../store/gameStateSlice'

// import { updateDemoState } from '../../store/demoSlice'

const mapState = (state: IRootState) => ({
    board: state.board,
    position: state.game.position,
    token: state.user.token,
    windowSize: state.topState.windowSize,
    mode: state.user.viewMode,
    theme: state.user.theme,
    pSelected: state.topState.selectedPuzzle,
    pResolved: state.topState.puzzleResolved,
    admin: (state.user as any).puzzlesAdmin,
    confirmed: state.gameState.gameConfirmed,
    playerColor: state.gameState.playerColor
})

const mapDispatch = {setWindowSize, updateBoardState, updatePosition, close}

const connector = connect(mapState, mapDispatch)

const Footer  = (props: ConnectedProps<typeof connector>) => {
    const location = useLocation()
    const navi = useNavigate()
    const dispatch = useDispatch()
    const type = useSelector(selectAppType)
    const isGame = useSelector(selectIsGame)
    const {
        selectedPuzzle, puzzleResolved
    } = useSelector(selectWithProps(['selectedPuzzle', 'puzzleResolved'], TopState)) as ITopState
    useEffect(() => {
        isDev() && console.warn('footer did mount')
        const handleResize = () => {
            const {innerHeight: height , innerWidth: width} = window
            props.setWindowSize({width, height})
        }
        window.addEventListener('resize', handleResize)
        const onClose = (e: any) => {
            e.preventDefault();
            const token = props.token
            if (token) props.close()
        }
        window.addEventListener("beforeunload", onClose)
        // console.log(LWS8x8)
        return () => {
            window.removeEventListener("resize", handleResize)
            window.removeEventListener("beforeunload", onClose)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const {
            windowSize,
            board: {boardSize: bs, cellSize: cS},
            updateBoardState,
            updatePosition,
            position: pos,
        } = props
        const {cellSize} = calcCellSize(type, windowSize, bs, cS)
        const cellsMap = updateCellsMap({...props.board, cellSize})
        updateBoardState({cellSize, cellsMap})
        if (!Object.keys(pos).length) return
        const position = copyObj(pos)
        for (const key in pos) {
            position[key].DOM = calcPiecePosition(key, cellsMap, cellSize)
        }
        updatePosition(position)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.windowSize, type])

    useEffect(() => {
        const page = location.pathname.split('/')[1]
        const {pResolved, pSelected, admin, confirmed, playerColor} = props
        if (page !== type) {
            const specialPage = (page === AppType.game && AppType.game)
                || (page === AppType.puzzles && AppType.puzzles)
                || (page === AppType.analysis && AppType.analysis)
            if ((isGame && specialPage && specialPage !== AppType.game)
                || (pSelected && !pResolved && specialPage === AppType.analysis && !admin)) {
                return navi(-1)
            }
            if (confirmed && specialPage && specialPage !== AppType.game) {
                const result = GameResult[oppositeColor(playerColor)]
                dispatch(endGame({game: {result}}))
                return navi(-1)
            }
            if (specialPage) {
                dispatch(setAppType(specialPage))
            }
            if (type === AppType.puzzles
                && selectedPuzzle 
                && puzzleResolved
                && specialPage !== AppType.analysis
            ) {
                dispatch(choosePuzzle(''))       
            }
                
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])
   
    return (
        <footer id='footer' className={'noselect mode-' + props.mode + ' theme-' + props.theme} >
            <ConnectionIndicator />
            <div className='copyright'>
                Ggames ltd&nbsp;&nbsp;
                <AiOutlineCopyright />
                {/* <img height="10px" width="10px" src={git} alt="github"/> */}
            </div>
            <WebSocketClient />
        </footer>
    )
}

export default (connector(Footer))
