import au0 from '../assets/sounds/move.mp3'
import au1 from '../assets/sounds/audio1.mp3'
import au2 from '../assets/sounds/audio2.mp3'
import au3 from '../assets/sounds/audio3.mp3'


const moveAccompaniment = (taken = 0) => {
    return new Audio(!taken ? au0 : (taken === 1 ? au1 : (taken === 2 ? au2 : au3)))
}

export default moveAccompaniment
