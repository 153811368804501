import {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './showMessage.scss'
import { AppType, IRootState, TowerTouched } from '../models/models'
// import {isDev } from '../local-engine/gameplay-helper-functions'
import { 
    gameHandlers, 
    pieceSetupHandlers,
    pieceRemoveHandlers,
    towerRemoveHandlers,
    towerSetupHandlers,
    EventHandlers,
    kingSetHandlers,
    IHookEvent,
    IMDownProps,
    IMMoveProps,
    IMUpProps
} from '../local-engine/eventHandlers'
import moveAudio from '../common/audio'
import { 
    selectBoardWithProps, selectGameWithProps, selectIsTouchable, selectUserWithProps, selectWithProps 
} from '../store/rootState&Reducer'
import { setPremove, setTouchedTower } from '../store/boardSlice'
import { makeMove, setMoveStep, tryUpdatePosition, updatePieceDOM, updatePieces, updatePosition } from '../store/gameSlice'
// import { getNextMove } from '../local-engine/gameplay-helper-fn'
import { Analysis, GameSettings } from '../constants/gameConstants'
import { copyObj, includeS, isDev, splitMove } from '../local-engine/gameplay-helper-fn'


const DefaultHadlers = {
    onMouseDown: (porps) => {}, 
    onMouseMove(props) {},
    onMouseUp(props) {},
} as EventHandlers

export const useHandler = () => {
    const dispatch = useDispatch()
    const onb = useSelector((state: IRootState) => !state.user.exp.onboardingPassed)
    const {
        towerTouched, cellSize, cellsMap, premove, boardSize: bs,
    } = useSelector(selectBoardWithProps([
        'towerTouched', 'cellSize', 'cellsMap', 'premove', 'boardSize'
    ]))
    const {
        removingPieces: RP, settingPosition: SP, settingKing: SK,
    } = useSelector(selectWithProps(["removingPieces", "settingPosition", "settingKing"], Analysis))
    const {gameStarted} = useSelector(selectWithProps(['gameStarted'], GameSettings))
    const GV = useSelector((state: IRootState) => state.gameSettings.gameVariant)
    const AT = useSelector((state: IRootState) => state.topState.type)
    const {
        nextMoves,
        moveStep,
        position,
        turn
    } = useSelector(selectGameWithProps([ 
        'nextMoves', 
        'moveStep', 
        'position',
        'turn'
    ]))
    
    const {volume} = useSelector(selectUserWithProps(['volume']))
    const [handlersWithDispatch, setHandlersWithDispatch] = useState(DefaultHadlers)
    const touchable = useSelector(selectIsTouchable)
    useEffect(() => {
        let handlers = gameHandlers as EventHandlers
        switch (true) {
            case SK: {
                handlers = kingSetHandlers
                break
            }
            case SP && RP && GV === 'towers': {
                handlers = towerRemoveHandlers
               break
            }
            case SP && RP && GV !== 'towers': {
                handlers = pieceRemoveHandlers
                break
            }
            case AT === 'game' || (AT === 'analysis' && !SP) || onb: {
                handlers = gameHandlers
                break
            }
            case AT === 'analysis' && SP && !RP && GV === 'towers': {
                handlers = towerSetupHandlers
                break
            }
            case AT === 'analysis' && SP && !RP && GV !== 'towers': {
                handlers = pieceSetupHandlers
                break
            }
            default: {
                handlers = gameHandlers
            }
        }
        const handlersWithDispatch = {
            onMouseDown: (event: IHookEvent) => {
                if ((Array.isArray(touchable) && !includeS(touchable, event.key)) || !touchable) return
                const props: IMDownProps = {
                    event, 
                    cellSize, 
                    cellsMap, 
                    nextMoves, 
                    moveStep,
                    position, 
                    turn,
                    bs,
                    towerTouched, 
                    premove: position[event.key]?.color !== turn 
                        ? premove 
                        : null as unknown as {from: string, to: string}
                }
                if (position[event.key]?.color === turn && premove) {
                    dispatch(setPremove(null))
                }
                const {
                    towerTouched: tt, pieces, towers, makedMove, moveStep: MS, premove: prm
                } = handlers.onMouseDown(props)
                if (prm !== undefined && AT === AppType.game) {
                    dispatch(setPremove(prm))
                    if (prm) return
                }
                if (makedMove) {
                    const from = splitMove(makedMove.move)[0]
                    if (makedMove.position[from] && !towerTouched?.possibleMoves[from]) {
                        isDev() && console.error('ivalid position', copyObj(makedMove))
                        delete makedMove.position[from]
                    }
                    dispatch(setMoveStep(MS))
                    if (volume) {
                        const audio = moveAudio()
                        audio.volume = volume
                        audio.play()
                    }
                    dispatch(makeMove(makedMove))
                    return
                }
                tt !== undefined && dispatch(setTouchedTower(tt))
                pieces && dispatch(updatePieces(pieces))
                towers && dispatch(updatePosition(towers))
            },
            onMouseMove: (event: IHookEvent) => {
                if (!towerTouched || !towerTouched.mouseDown) return
                const props: IMMoveProps = {event, towerTouched, position}
                const {key, DOM} = handlers.onMouseMove(props)
                DOM && dispatch(updatePieceDOM({key, DOM}))
            },
            onMouseUp: (event: IHookEvent) => {
                if ((!towerTouched && !premove) || (!SP && !nextMoves.length)) return
                const TT = towerTouched as TowerTouched
                const props: IMUpProps = {
                    event, 
                    towerTouched: TT, 
                    position, 
                    cellSize, 
                    cellsMap, 
                    nextMoves, 
                    moveStep, 
                    turn, 
                    premove
                }
                const {
                    towers, makedMove, pieces, moveStep: MS = 0, towerTouched: tt = null, premove: prm
                } = handlers.onMouseUp(props)
                if (prm) {
                    dispatch(setPremove(prm))
                    return
                }
                dispatch(setTouchedTower(tt))
                if (makedMove) {
                    const from = splitMove(makedMove.move)[0]
                    if (makedMove.position[from]) {
                        isDev() && console.error('ivalid position', copyObj(makedMove))
                        delete makedMove.position[from]
                    }
                    dispatch(setMoveStep(MS))
                    if (volume) {
                        const audio = moveAudio()
                        audio.volume = volume
                        audio.play()
                    }
                    dispatch(makeMove(makedMove))
                    return
                }
                if (towers && pieces) {
                    isDev() && console.error('fail in mouse up', pieces, towers)
                }
                if (towers) {
                    dispatch(tryUpdatePosition(towers))                
                } 
                else if (pieces) dispatch(updatePieces(pieces))
                !SP && dispatch(setMoveStep(MS))
            }
        }
        setHandlersWithDispatch(handlersWithDispatch as any)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [GV, SP, AT, RP, SK, cellSize, position, nextMoves, moveStep, towerTouched, gameStarted, premove])
    
    return handlersWithDispatch
}
