/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, memo } from 'react'
import { useSelector } from 'react-redux';

import {TowerComponent} from '../Tower/GamePiece'
import {BoardComponent} from './Board'
import { BaseBoardSize, MADelay } from '../../constants/gameConstants';
import { 
    calcPiecePosition,
    createDemoCellsMap,
    createDemoStartPosition,  
    updateDemoStartPosition, 
    updatePiecesPosition 
} from '../../local-engine/board-helper-fn';
import { 
    Move, IBoardPieces as IBP, CellsMap, IBaseDemo, IDemoState, 
    IAnimBoard
} from '../../models/models';
import { Demos } from '../../constants/debutes-demos';
import { delay, getDemoCellSize, splitMove } from '../../local-engine/gameplay-helper-fn';
import { selectUserWithProps } from '../../store/rootState&Reducer';
import { selectWindowSize } from '../../store/topStateSlice';
import { selectTheme } from '../../store/userSlice';


export const demoBase = (cS: number, n = 0, bS = BaseBoardSize): IBaseDemo => {
    const state = {
        ...createDemoStartPosition(cS, bS),
        moves: Demos[n],
        bS,
    }
    return state
}
const startState = (bS = BaseBoardSize): IDemoState => ({
    moveNum: 0,
    demoStarted: false,
    move: [] as string[],
    mStep: 0 
})

export const AnimatedBoard = memo((
    props: {num: number, stop?: boolean, menu?: boolean, cS?: number, bS?: number, pCs?: string[]}
) => {
    const winSize = useSelector(selectWindowSize)
    const {
        num, 
        stop = false, 
        menu = false, 
        bS = BaseBoardSize, 
        cS: cellS = getDemoCellSize(winSize, props.bS || BaseBoardSize)
    } = props
    const { pieceColors } =  useSelector(selectUserWithProps(['pieceColors']))
    const pCs = props.pCs 
        ? props.pCs 
        : pieceColors
    const [base, setBase] = useState(demoBase(cellS, num))
    const {moves, cS, position, cM} = base 
    // console.error('anim base', base, demoBase(cellS, num).position, cellS)
   
    useEffect(() => {
        if (props.cS) {
            return
        }
        if (menu && winSize.width < 560 && base.cS !== 20) {
            const cMap = createDemoCellsMap(20, bS).cM
            setBase((state) =>({
                ...state, 
                cS: 20, 
                cM: cMap, 
                position: updatePiecesPosition(base.position, cM, 20)
            }))
        }
        if ((!menu || winSize.width >= 560) && winSize.width < 960 && base.cS !== 25) {
            const cMap = createDemoCellsMap(25, bS).cM
            setBase((state) =>({ 
                ...state, 
                cS: 25, 
                cM: cMap, 
                position: updatePiecesPosition(base.position, cMap, 25)
            }))
        } 
        if (winSize.width >= 960 && base.cS !== 30) {
            const cMap = createDemoCellsMap(30, bS).cM
            setBase((state) => ({
                ...state, 
                cS: 30, 
                cM: cMap, 
                position: updatePiecesPosition(base.position, cMap, 30)
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [winSize])

    return <AnimatedBoardComponent 
                stop={stop} 
                moves={moves} 
                cS={cS} 
                cM={cM} 
                stPos={position} 
                bS={bS}
                pCs={pCs}
            />
})

export const AnimatedBoardComponent = memo((props: IAnimBoard) => {
        const {stPos, cS, moves, cM, bS, pCs } = props
    const theme = useSelector(selectTheme)
    const [demo, setDemo] = useState({...startState(), position: stPos})
    const ref = useRef({nextPos: {} as IBP, transPos: {} as IBP})

    useEffect(() => {
        const {stop, cS = 30} = props
        if (stop) { return }
        const position = updateDemoStartPosition(stPos, cM, cS).position
        setDemo(state => ({
            ...state,
            position
        }))
        makeDemoMoveFirstStep(moves[demo.moveNum])
    }, [])

    useEffect(() => {
        if (props.stop) {
            return
        }
        const {mStep = 0, move = [], moveNum} = demo
        if (!moves[moveNum]) {
           resetPosition()
           return
        }
        if (mStep === 0 && !move.length ) {
            makeDemoMoveFirstStep(moves[demo.moveNum])
        } else {
            if (mStep + 1 === move.length) {
                lastStep()
            } else {
                makeDemoMoveStep()
            }
        } 
    }, [demo.position, props.stop])

    useEffect(() => {
        if (demo.move?.length) {
            updateRef(cM, cS)                
        }
        const position =  updateDemoStartPosition(demo.position, cM, cS).position
        setDemo((state) => ({
            ...state, 
            position
        }))
    }, [cS])

    const makeDemoMoveFirstStep = async (_move: Move) => {
        if (props.stop || !_move) {
            return
        }
        const {move, position: pos} = _move as Move
        const moveArr = splitMove(move)
        const [from, to] = [moveArr[0], moveArr[moveArr.length - 1]]
        await delay(!demo.moveNum ? MADelay*2 : MADelay)
        const nextPos = updatePiecesPosition(pos, cM, cS)
        const transPos = updatePiecesPosition(demo.position, cM, cS)
        transPos[to] = transPos[from]
        delete transPos[from]
        ref.current = {transPos, nextPos}
        setDemo(state => ({...state, position: transPos, mStep: 1, move: moveArr}))
    }

    const makeDemoMoveStep = async () => {
        const {move = [], mStep = 1} = demo
        const [from, to] = [move[mStep], move[move.length - 1]]
        await delay(MADelay)
        const transPos = updatePiecesPosition(ref.current.transPos, cM, cS, bS, [to])
        if (!transPos[to]) {
            console.error(transPos, ref, from, to, mStep)
            return
        }
        transPos[to].DOM = calcPiecePosition(from, cM, cS)
        ref.current = {...ref.current , transPos}
        setDemo(state => ({...state, position: transPos, mStep: mStep + 1}))
    }

    const lastStep = async() => {
        const position = updatePiecesPosition(ref.current.nextPos, cM, cS)
        await delay(MADelay)
        const moveNum = (demo.moveNum + 1) % (moves.length + 1)
        setDemo(state => ({
            ...state, position, aStep: 0, mStep: 0, moveNum, move: []
        }))
    }

    const resetPosition = async() => {
        await delay(MADelay*2)
        const pos = updatePiecesPosition(stPos, cM, cS) as IBP
        setDemo(state => ({...state, position: pos, mStep: 0, moveNum: 0, move: []}))
    }

    const updateRef = (cM: CellsMap, cS: number) => {
        const {move = [], mStep = 0} = demo
        const nextPos = updatePiecesPosition(ref.current.nextPos || {}, cM, cS)
        const transPos = updatePiecesPosition(ref.current.transPos || {}, cM, cS)
        transPos[move[move.length - 1]].DOM = calcPiecePosition(move[mStep], cM, cS)
        ref.current = {nextPos, transPos}
    }

    // console.log(pCs)
    const Towers = []
    for (const key in demo.position) {
        Towers.push(<TowerComponent 
            key={key} 
            posKey={key} 
            gamePiece={demo.position[key]} 
            view={'face'} 
            pieceColors={pCs} 
            cellSize={cS} 
        />)
    }
    const WrapperClass = `board__wrapper theme-${theme} demo-board`

    return (
        <div className={WrapperClass}>
            {Towers}
            <BoardComponent bs={bS} bw={bS*cS}/>
        </div>
    )  
})
