
import { Outlet, useLocation } from 'react-router-dom'
import './auth.scss'
import SigninPage from './SigninPage'
import { useSelector } from 'react-redux'
import { selectTheme, selectMode } from '../../store/userSlice'
import { FadeIn } from '../../UI-components/FadeIn/FadeIn'


const AuthPage = () => {
    const theme = useSelector(selectTheme)
    const mode = useSelector(selectMode)
    const location = useLocation()  
    return (
        <FadeIn>
            <div className={`page-wrapper noselect mode-${mode} theme-${theme}`}>
                {location.pathname === '/auth' ? <SigninPage /> :  <Outlet />}
            </div>
        </FadeIn>
    )
           
}

export default AuthPage
