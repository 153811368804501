import { delay, put, select, takeLatest } from 'redux-saga/effects'
import {
    AppType,
    GameResult,
    IGame,
    IGoTo,
    IBoardPieces as IBP,
    IRootState,
    PieceColor,
    IBoardPieces,
    MMRResult,
    IMovesPair,
    Move,
    IPuzzle,
    GameVariant,
} from '../../models/models'

import { confirmGameStarted, endGame, offerDraw, setBClock, setWClock } from '../gameStateSlice'
import {
    updateGame, 
    idling, 
    updatePosition,
    makeMove,
    goToPosition,
    repeatLastMove,
    help,
    playMoves,
    tryUpdatePosition,
    InitialGame,
    updateMoves,
} from '../gameSlice'
import {
    addSolutionToMoves,
    checkDrawTowers,
    checkIdle,
    copyObj,
    filterAndSort,
    fullPosition,
    getFromTo,
    getMoves,
    getMovesToPlay,
    getNextMove,
    getPrevPosition,
    isDev,
    movesFromSolution,
    oppositeColor,
    removeMandatory,
    splitMove,
} from '../../local-engine/gameplay-helper-fn'
import { moveR } from '../../local-engine/move-r'
import { getNewPuzzles, sendWsMessage, setAppState, setPuzzles, setPuzzleStatus, showAds, skipPuzzle, startEngine } from '../topStateSlice'
import { UnknownAction } from 'redux'
import {
    calcPiecePosition,
    createStartPosition,
    updatePiecesPosition 
} from '../../local-engine/board-helper-fn'
import moveAudio from '../../common/audio'
import { saveUser } from '../userSlice'
import storage from '../../common/storage'
import { InitialAnalysisState, selectGame, updateAnalysisState } from '../analysisSlice'
import { setGameVariant } from '../gameOptionsSlice'
import { setPremove, setTouchedTower, turnBoard } from '../boardSlice'
import { MPS, TPS, TwelveH } from '../../constants/gameConstants'
import clocksService from '../../common/clock-service'

function* ifOnboardingPassed(resolvedPuzzles: string[], GV: GameVariant) {
    yield put(showAds(true))
    yield put(setPuzzles(filterAndSort(storage.getPuzzles(), resolvedPuzzles, GV).slice(0, 6)))
}

function* updateStateOnMove(
    move:string, transPos: IBoardPieces , nextPos: IBoardPieces
) {
    const {
        game: {moves: ms, lastMove, turn: tn},
        gameState: {gameStarted, gameConfirmed, playerColor},
        topState: {type}
    } = (yield select()) as IRootState
    const {turn, num} = (type === AppType.puzzles || type === AppType.analysis) && !lastMove.turn
        ? {turn: tn, num: 0}
        : getNextMove(lastMove)
    const moves = ms.slice(0, num)
    moves[num] = {...ms[num], [turn]: {move, position: nextPos}}
    if (gameStarted && !gameConfirmed && turn === PieceColor.black) {
        yield put(confirmGameStarted(true))
    }
    const nextMoves = type === AppType.game && turn === playerColor 
        ? [] 
        : moveR.getPossibleMoves(nextPos, oppositeColor(turn), true)
    const payload = {
        turn: oppositeColor(turn),
        position: transPos,
        moves,
        lastMove: {num, turn},
        moveStep: 0,
        nextMoves,
        helpRequest: false
    }
    yield put(updateGame(payload))
    yield delay(100)
    if (nextMoves[0]?.takenPieces) {
        const posWithMandatory = copyObj(nextPos)
        for (const move of nextMoves) {
            const key = move.move[0]
            posWithMandatory[key].mandatory = true
        }
        yield put(updatePosition(posWithMandatory))
    } else if (turn !== playerColor && type === AppType.game) {
        yield put(updatePosition(nextPos))
    }
    return nextMoves
}

function* workerPremovedMove(
    premove: {from: string, to: string}, nextMoves: MMRResult[], engine: boolean
) {
    yield put(setPremove(null))
    const premoveMove = nextMoves.filter(m => 
        m.move[0] === premove.from && m.move[m.move.length - 1] === premove.to
    )[0]
    if (!premoveMove) {
        return
    }    
    yield delay(100)
    const {board: {cellSize, cellsMap}} = (yield select()) as IRootState
    const transPos = copyObj(premoveMove.endPos)
    transPos[premove.to].DOM = calcPiecePosition(premove.from, cellsMap, cellSize)
    yield put(updatePosition(transPos))
    yield put(makeMove({
        move: premoveMove.move.join(premoveMove.takenPieces ? TPS : MPS),
        position: premoveMove.endPos
    }))
}

function* workerIdleAndDraw(move: string) {
    const {
        game: {moves, idleInRow: idle, turn: nTurn, position},
    } = (yield select()) as IRootState
    const idleMove = checkIdle(move, position as IBP)
    const turn = oppositeColor(nTurn)
    if (idleMove) {
        const idleInRow = idle + 1
        if (turn === PieceColor.black) {
            yield put(idling(idleInRow))
        }
        const props = {idleInRow, moves, position, turn} as IGame
        const draw = checkDrawTowers(props)
        if (draw) {
            yield put(endGame({game: {result: GameResult.draw}}))
        }
    } else {
        yield put(idling(0))
    }
}

function* workerMove(action: UnknownAction) {
    if (isDev()) console.warn('game move', action)
    yield delay(50)
    const {
        gameState: {playerColor}, 
        game: {turn},
        user: {volume, exp: {onboardingPassed}},
        topState: {type},
        gameSettings: {rivalType},
        board: {towerTouched}
    } = (yield select()) as IRootState
    if (towerTouched) {
        yield put(setTouchedTower(null))
    }
    if (volume && (turn === playerColor || type !== AppType.game)) {
        yield delay(70)
    }
    if (volume && type === AppType.game && turn !== playerColor) {
        const audio = moveAudio()
        audio.volume = volume
        audio.play()
        yield delay(180)
    }
    if (type === AppType.game && rivalType !== 'engine') {
        isDev() && console.log('switch clock', turn)
        clocksService.switchClock()
    }
    if (type === AppType.puzzles || !onboardingPassed) {
        yield workerMakePuzzMove(action)
    } else if (type === AppType.analysis) {
        yield workerMakeAMove(action)
    } else {
        yield playerColor === turn 
            ? workerPlayerMove(action) 
            : workerMoveAgainstPlayer(action)
    }
    
}

function* workerMoveAgainstPlayer(action: UnknownAction) {
    const {
        board: {cellSize, cellsMap, premove},
        game: {position: cPos},
        gameState: {playerColor, drawOffered},
        gameSettings: {rivalType}
    } = (yield select()) as IRootState
    const {move, position: pos, whiteClock, blackClock} = action.payload as any
    const fromTo = getFromTo(move)
    const nextPos = rivalType === 'engine'
        ? updatePiecesPosition(pos as IBoardPieces, cellsMap, cellSize)
        : (pos 
            ? fullPosition(pos, {cellSize, cellsMap})
            : moveR.makeMoves([move], cPos)
        )
    const transPos = copyObj(nextPos)
    transPos[fromTo[1]].DOM = calcPiecePosition(fromTo[0], cellsMap, cellSize)
    const nextMoves: MMRResult[] = yield updateStateOnMove(move, transPos, nextPos)
    if (rivalType === 'engine' ) {
        if (!nextMoves?.length) {
            const payload = {game: {result: GameResult[oppositeColor(playerColor)]}}
            yield put(endGame(payload))
            return
        }
        yield workerIdleAndDraw(move)
    }
    if (drawOffered) {
        yield put(offerDraw(false))
    }
    if (whiteClock) yield put(setWClock(whiteClock))
    if (blackClock) yield put(setBClock(blackClock))
    if (premove) {
        yield workerPremovedMove(premove, nextMoves, rivalType === 'engine')
    }
}

function* workerPlayerMove(action: UnknownAction) {
    const {
        user: {token, userId},
        gameState: {gameKey},
        gameSettings: {rivalType},
        board: {cellsMap, cellSize, boardSize}
    } = (yield select()) as IRootState
    const {move, position} = action.payload as any
    const nextPos = updatePiecesPosition(position, cellsMap, cellSize, boardSize)
    yield updateStateOnMove(move, nextPos, nextPos)
    if (rivalType === 'engine') {
        yield workerIdleAndDraw(move)
    } else if (token) {
        const wsPayload = {
            gameKey,
            move,
        }
        yield put(sendWsMessage({ message: 'game move', payload: wsPayload,  from: userId }))
    }
}

function* workerGoToPosition(action: UnknownAction) {
    const lastMove = action.payload as IGoTo
    const {
        game: {moves: ms, startPosition, startTurn},
        gameState: {playerColor},
        topState: {type}, 
        gameSettings: {rivalType, competitionType},
        analysis: {gameMoves},
        board: {cellsMap, cellSize, towerTouched}
    } = (yield select()) as IRootState
    isDev() && console.log(action, ms)
    if (towerTouched) {
        yield put(setTouchedTower(null))
    }
    const invalidState = (rivalType === 'player' || competitionType === 'ranked') 
        && type === AppType.game
    if (invalidState || lastMove.num < 0) {
        return
    }
    const moves = type !== AppType.analysis
        ? ms 
        : getMoves(ms as IMovesPair[], gameMoves as IMovesPair[])
    const {position} = lastMove.turn  
        ? moves[lastMove.num][lastMove.turn]!
        : {position: startPosition}
    const turn = lastMove.turn
        ? oppositeColor(lastMove.turn) 
        : (type === AppType.analysis ? startTurn : PieceColor.white)
    const nextMoves = type === AppType.game && turn !== playerColor
    ? []
    : moveR.getPossibleMoves(position, turn, true)
    const GameProps = {
        position: updatePiecesPosition(removeMandatory(position), cellsMap, cellSize),
        turn,
        lastMove,
        nextMoves
    }
    yield put(updateGame(GameProps))
    yield delay(100)
    if (nextMoves[0]?.takenPieces) {
        const posWithMandatory = copyObj(GameProps.position)
        for (const move of nextMoves) {
            const key = move.move[0]
            posWithMandatory[key].mandatory = true
        }
        yield put(updatePosition(posWithMandatory))
    }
}

function* workerRepeatLast() {
    const {
        game: {moves, lastMove, turn},
        board: {boardSize, cellsMap, cellSize},
        gameState: {playerColor},
        gameSettings: {gameVariant: GV}
    } = (yield select()) as IRootState
    if (!moves[0]?.white || turn !== playerColor) {
        return
    }
    const {position, move} = moves[lastMove.num][lastMove.turn] || {}
    if (!position || !move) return
    let prevPos
    if (moves.length === 1 && turn === PieceColor.black) {
        const pos = createStartPosition(boardSize, GV)
        prevPos = updatePiecesPosition(pos, cellsMap, cellSize)
    } else {
        prevPos = updatePiecesPosition(getPrevPosition(moves, turn), cellsMap, cellSize)
    }
    const moveArr = splitMove(move)
    const [from, to] = [moveArr[0], moveArr[moveArr.length - 1]]
    prevPos[to] = prevPos[from]
    delete prevPos[from]
    yield animateMove(moveArr, prevPos, position)
}

function* playPuzzleMoves(resolved: number, eSol = false) {
    const {
        topState: {solution = [], puzzleResolved, eSolution = []},
        game: {position, startPosition, startTurn},
        gameSettings: {gameVariant: GV},
        board: {cellSize, cellsMap},
        user: {exp: {onboardingPassed}}
    } = (yield select()) as IRootState
    if (!resolved) {
        yield delay(700)
        yield put(updatePosition(updatePiecesPosition(startPosition, cellsMap, cellSize)))
        yield delay(200)
    }
    yield put(updateMoves(addSolutionToMoves([], solution)))
    const movesToPlay =  (!eSol ? solution : eSolution).slice(resolved)
    yield delay(50)
    const {user: {resolvedPuzzles = []}} = (yield select()) as IRootState
    if (!movesToPlay.length) {
        yield delay(onboardingPassed ? 1000 : 2000)
        yield put(setPuzzleStatus({...puzzleResolved, animated: true}))
        if (onboardingPassed) {
            yield ifOnboardingPassed(resolvedPuzzles, GV)
        }
        return
    }
    yield animateMoves(movesToPlay, resolved ? position : startPosition)
    const newPos = movesToPlay[movesToPlay.length - 1].position
    const nextPos = updatePiecesPosition(newPos, cellsMap, cellSize)
    yield put(updateGame({
        turn: oppositeColor(startTurn),
        position: nextPos,
    }))
    yield delay(onboardingPassed ? 1000 : 3000)
    yield put(setPuzzleStatus({...puzzleResolved, animated: true}))
    if (onboardingPassed) {
        yield ifOnboardingPassed(resolvedPuzzles, GV)
    }
}

function* workerPlayMoves(action: UnknownAction) {
    if (!action.payload) return
    const {
        topState: {type, puzzleResolved}, board: {towerTouched}
    } = (yield select()) as IRootState
    if (towerTouched) yield put(setTouchedTower(null))
    if (type === AppType.puzzles) {
        yield playPuzzleMoves(+(puzzleResolved?.resolved || 0))
        return
    }
    const {
        analysis: {gameMoves}, 
        game: {lastMove, position, moves: ms},
    } = (yield select()) as IRootState
    const moves = getMoves(ms as IMovesPair[], gameMoves as IMovesPair[])
    const newLast = {
        num: moves.length - 1, 
        turn: moves[moves.length -1].black ? PieceColor.black : PieceColor.white
    }
    const movesToPlay = getMovesToPlay(moves, lastMove)
    yield animateMoves(movesToPlay, position)
    const newPos = movesToPlay[movesToPlay.length - 1].position
    yield put(updateGame({
        lastMove: newLast,
        turn: oppositeColor(newLast.turn),
        nextMoves: moveR.getPossibleMoves(newPos, oppositeColor(newLast.turn), true)
    }))
}

function* animateMoves(moves: Move[], startPos: IBoardPieces, ind = 0): any {
    const {
        board: {cellsMap, cellSize}, game: {playingMoves}, topState: {type}
    } = (yield select()) as IRootState
    if (ind === moves.length || (!playingMoves && type === AppType.analysis)) { return }
    const moveArr = splitMove(moves[ind].move)
    const transPos = updatePiecesPosition(startPos, cellsMap, cellSize)
    transPos[moveArr[moveArr.length- 1]] = transPos[moveArr[0]]
    delete transPos[moveArr[0]]
    const endPos = moves[ind].position
    yield animateMove(moveArr, transPos, endPos, 1, 500)
    yield animateMoves(moves, endPos, ind + 1)
}

export function* animateMove(
    move: string[], startP: IBP, endP: IBP, step = 1, tout = 300
): any {
    const to = move[move.length - 1]
    const {board: {cellsMap, cellSize, boardSize}} = (yield select()) as IRootState
    const pos = updatePiecesPosition(startP, cellsMap, cellSize, boardSize, [to])
    pos[to].DOM = calcPiecePosition(move[step - 1], cellsMap, cellSize)
    yield put(updatePosition(pos))
    yield delay(step === 1 ? 2 * tout : tout)
    if (move.length === step + 1) {
        const {board: {cellsMap, cellSize}} = (yield select()) as IRootState
        const pos = updatePiecesPosition(endP, cellsMap, cellSize)
        yield put(updatePosition(pos))
    } else {
        const {board: {cellsMap, cellSize}} = (yield select()) as IRootState
        const transP = updatePiecesPosition(startP, cellsMap, cellSize, boardSize, [to])
        transP[to].DOM = calcPiecePosition(move[step], cellsMap, cellSize)
        yield animateMove(move, transP, endP, step + 1)
    }
}

function* workerHelp() {
    const {
        game: {hintMove, position},
        board: {cellSize, cellsMap, boardSize},
        user: {exp: {onboardingPassed}},
        topState: {solution}
    } = (yield select()) as IRootState
    if ((onboardingPassed && !hintMove) || (!onboardingPassed && !solution)) return
    const move = (hintMove || (solution as Move[])[0].move)
    const moveArr = splitMove(move)
    const nextPos = updatePiecesPosition(
        moveR.makeMoves([move], position), cellsMap, cellSize
    )
    const transPos = copyObj(position)
    transPos[moveArr[moveArr.length - 1]] = transPos[moveArr[0]]
    delete transPos[moveArr[0]]
    yield animateMove(moveArr, transPos, nextPos)
    yield delay(300)
    const {board: {cellSize: cS, cellsMap: cM}} = yield select()
    const transPos2 = updatePiecesPosition(transPos, cM, cS, boardSize, moveArr.slice(-1))
    transPos2[moveArr[moveArr.length -1]].DOM = calcPiecePosition(moveArr[0], cM, cS)
    yield put(updatePosition(transPos2))
    yield put(updatePosition(updatePiecesPosition(position, cM, cS)))
}

function* workerMakePuzzMove(action: UnknownAction) {
    const {
        topState: {solution = [], eSolution = [], selectedPuzzle, puzzles, puzzleResolved},
        board: {cellsMap, cellSize},
        user: {exp, resolvedPuzzles = [], token}
    } = (yield select()) as IRootState
    if (!selectedPuzzle || puzzleResolved) return
    const {move, position: nextPos} = action.payload as Move
    const position = updatePiecesPosition(nextPos as IBoardPieces, cellsMap, cellSize)
    const eSol = move === eSolution[0]?.move
    const resolved = move === solution[0].move || eSol
    isDev() && console.log(resolved, move, solution, eSolution, eSol)
    yield updateStateOnMove(move, position, position)
    const {level, gType} = puzzles.find(p => p._id === selectedPuzzle) || {} as IPuzzle
    const nExp = resolved ? {...exp, [gType]: {...exp[gType], puzzles: level}} : exp
    if (!resolvedPuzzles.includes(selectedPuzzle)) {
        const nResolved = resolvedPuzzles.concat(selectedPuzzle)
        yield put(saveUser({exp: nExp, resolvedPuzzles: nResolved}))
        storage.saveUser({exp: nExp, resolvedPuzzles: nResolved})
    }
    yield put(setAppState({
        puzzles: puzzles.filter(p => p._id !== selectedPuzzle),
        puzzleResolved: {resolved}
    }))
    if (token && Date.now() - storage.getRequestDate() > TwelveH) {
        yield put(getNewPuzzles(gType))
    }
    yield playPuzzleMoves(+resolved, eSol)
}

function* workerMakeAMove(action: UnknownAction) {
    yield delay(50)
    const {
        board: {cellSize, cellsMap},
    } = (yield select()) as IRootState
    const {move, position: nextPos} = action.payload as Move
    const position = updatePiecesPosition(nextPos, cellsMap, cellSize)
    yield updateStateOnMove(move, position, position)
}

function* updatePos(action: UnknownAction) {
    const {analysis: {settingPosition}} = yield select()
    if (settingPosition) {
        yield delay(10)
    }
    yield put(updatePosition(action.payload))
}


function* workerSkip() {
    const {topState: {puzzleResolved, selectedPuzzle, puzzles}} = yield select()
    if (!selectedPuzzle || puzzleResolved) return
    yield put(setAppState({
        puzzles: puzzles.filter((p: IPuzzle) => p._id !== selectedPuzzle),
        puzzleResolved: {resolved: false}
    }))
    yield playPuzzleMoves(0)
    yield delay(100)
    
}

function* workerSelectGame(action: UnknownAction) {
    const {user: {userId}, topState: {localEngine}} = (yield select()) as IRootState
    if (localEngine) { 
        yield put(startEngine(false))
        yield delay(0)
    }
    const {mHistory, GV, bSize, black} = action.payload as any
    const reversed = userId === black
    yield put(setGameVariant({GV, boardSize: bSize}))
    moveR.setProps({GV, size: bSize})
    const position = createStartPosition(bSize, GV)
    const movesWithPosition = movesFromSolution(mHistory.split('_'), position, moveR)
    const moves = [] 
    let move = {} as IMovesPair
    for (let i = 0; i < movesWithPosition.length; i++) {
        if (i%2) {
            move.black = {...movesWithPosition[i]}
            moves.push({...move})
            move = {} as IMovesPair
        } else {
            move.white = {...movesWithPosition[i]}
        }
    }
    if (move.white) {
        moves.push(move)
    }
    const nextMoves = moveR.getPossibleMoves(position, PieceColor.white) 
    yield put(updateGame({
        ...InitialGame, position, startPosition: position, moves, nextMoves
    }))
    yield put(updateAnalysisState({
        ...InitialAnalysisState, settingPosition: false, startTurn: PieceColor.white
    }))
    yield put(turnBoard(reversed))
    yield put(startEngine(true))
}

export default function* watcherGame() {
    yield takeLatest(makeMove, workerMove)
    yield takeLatest(goToPosition, workerGoToPosition)
    yield takeLatest(playMoves, workerPlayMoves)
    yield takeLatest(repeatLastMove, workerRepeatLast)
    yield takeLatest(help, workerHelp)
    yield takeLatest(tryUpdatePosition, updatePos)
    yield takeLatest(skipPuzzle, workerSkip)
    yield takeLatest(selectGame, workerSelectGame)
}
