import React from 'react';
import './spinner.scss';
import { selectLanguage } from '../../store/userSlice';
import { useSelector } from 'react-redux';
import { I18n } from '../../assets/i18n';
/**
 * This component designed to show to user, that game data loading is in process
 * and used in the landing page and game.
 * Spinner's animation implemented with css.
 */

const Spinner = () => {
    const ln = useSelector(selectLanguage)
    const i18n = I18n[ln.slice(0, 2)]
    return (
        <div className="waiting-rival-spinner">
            <div title={i18n.cancelTitle} className="logo"></div>
        </div>
    );
};

export default Spinner;
